import React from "react";
import { Link } from "react-router-dom";
import "./Sidebar.scss";

const navItems = [
    { path: '/path1', title: 'Item 1' },
    { path: '/path2', title: 'Item 2' },
];

const Sidebar = () => {
    return (
        <div className="sidebar">
            <ul>
                {navItems.map(item => (
                    <li key={item.path}>
                        <Link className="sidebar-link" to={item.path}>{item.title}</Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Sidebar;
