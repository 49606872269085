import React from "react";
import "./GrowthRateTable.css";
import { roundInMillions, roundRatio } from "../../utils/DataTransformations";
import { useDispatch, useSelector } from "react-redux";
import { setUserFreeCashFlow } from "../../redux/slices/userFreeCashFlowSlice";

interface GrowthRateTableProps {
    growthTableCashFlows: {
        offset: number;
        freeCashFlow: number;
        operatingCashFlow: number;
        capitalExpenditure: number;
        cagr_check: number;
    }[];
    growthTableIncomeStatement: {
        offset: number;
        revenue: number;
        eps: number;
    }[];
    growthTableBalanceSheet: {
        offset: number;
        totalEquity: number;
    }[];
}

const GrowthRateTable = ({
    growthTableCashFlows,
    growthTableIncomeStatement,
    growthTableBalanceSheet,
    
}: GrowthRateTableProps) => {
    const dispatch = useDispatch();
    const userFreeCashFlow = useSelector((state: any) => state.userFreeCashFlow);

    return (
        <table className="growth-rate-table">
            <thead>
                <tr>
                    <th className="growth-rate-table-header"></th>
                    <th className="growth-rate-table-header">TTM - 10 YR</th>
                    <th className="growth-rate-table-header">TTM - 5 YR</th>
                    <th className="growth-rate-table-header">TTM - 3 YR</th>
                    <th className="growth-rate-table-header">TTM - 1 YR</th>
                    <th className="growth-rate-table-header">TTM</th>
                    <th className="growth-rate-table-header">1 YR CAGR</th>
                    <th className="growth-rate-table-header">3 YR CAGR</th>
                    <th className="growth-rate-table-header">5 YR CAGR</th>
                    <th className="growth-rate-table-header">10 YR CAGR</th>
                </tr>
            </thead>
            <tbody>
                <tr className="growth-rate-table-row">
                    <td className="growth-rate-table-cell">Revenue</td>
                    <td className="growth-rate-table-cell">
                        {roundInMillions(
                            growthTableIncomeStatement[0]?.revenue ?? 0
                        )}
                    </td>
                    <td className="growth-rate-table-cell">
                        {roundInMillions(
                            growthTableIncomeStatement[1]?.revenue ?? 0
                        )}
                    </td>
                    <td className="growth-rate-table-cell">
                        {roundInMillions(
                            growthTableIncomeStatement[2]?.revenue ?? 0
                        )}
                    </td>
                    <td className="growth-rate-table-cell">
                        {roundInMillions(
                            growthTableIncomeStatement[3]?.revenue ?? 0
                        )}
                    </td>
                    <td className="growth-rate-table-cell">
                        {roundInMillions(
                            growthTableIncomeStatement[4]?.revenue ?? 0
                        )}
                    </td>
                    <td className="growth-rate-table-cell">
                        {roundRatio(
                            Math.pow(
                                (growthTableIncomeStatement[4]?.revenue ?? 0) /
                                    (growthTableIncomeStatement[3]?.revenue ??
                                        1),
                                1 / (growthTableIncomeStatement[3]?.offset ?? 1)
                            ) - 1
                        )}
                    </td>
                    <td className="growth-rate-table-cell">{roundRatio(
                            Math.pow(
                                (growthTableIncomeStatement[4]?.revenue ?? 0) /
                                    (growthTableIncomeStatement[2]?.revenue ??
                                        1),
                                1 / (growthTableIncomeStatement[2]?.offset ?? 1)
                            ) - 1
                        )}</td>
                    <td className="growth-rate-table-cell">{roundRatio(
                            Math.pow(
                                (growthTableIncomeStatement[4]?.revenue ?? 0) /
                                    (growthTableIncomeStatement[1]?.revenue ??
                                        1),
                                1 / (growthTableIncomeStatement[1]?.offset ?? 1)
                            ) - 1
                        )}</td>
                    <td className="growth-rate-table-cell">{roundRatio(
                            Math.pow(
                                (growthTableIncomeStatement[4]?.revenue ?? 0) /
                                    (growthTableIncomeStatement[0]?.revenue ??
                                        1),
                                1 / (growthTableIncomeStatement[0]?.offset ?? 1)
                            ) - 1
                        )}</td>
                </tr>
                <tr className="growth-rate-table-row">
                    <td className="growth-rate-table-cell">EPS</td>
                    <td className="growth-rate-table-cell">
                        {(growthTableIncomeStatement[0]?.eps ?? 0).toFixed(2)}
                    </td>
                    <td className="growth-rate-table-cell">
                        {(growthTableIncomeStatement[1]?.eps ?? 0).toFixed(2)}
                    </td>
                    <td className="growth-rate-table-cell">
                        {(growthTableIncomeStatement[2]?.eps ?? 0).toFixed(2)}
                    </td>
                    <td className="growth-rate-table-cell">
                        {(growthTableIncomeStatement[3]?.eps ?? 0).toFixed(2)}
                    </td>
                    <td className="growth-rate-table-cell">
                        {(growthTableIncomeStatement[4]?.eps ?? 0).toFixed(2)}
                    </td>
                    <td className="growth-rate-table-cell">
                        {roundRatio(
                            Math.pow(
                                (growthTableIncomeStatement[4]?.eps ?? 0) /
                                    (growthTableIncomeStatement[3]?.eps ??
                                        1),
                                1 / (growthTableIncomeStatement[3]?.offset ?? 1)
                            ) - 1
                        )}
                    </td>
                    <td className="growth-rate-table-cell">{roundRatio(
                            Math.pow(
                                (growthTableIncomeStatement[4]?.eps ?? 0) /
                                    (growthTableIncomeStatement[2]?.eps ??
                                        1),
                                1 / (growthTableIncomeStatement[2]?.offset ?? 1)
                            ) - 1
                        )}</td>
                    <td className="growth-rate-table-cell">{roundRatio(
                            Math.pow(
                                (growthTableIncomeStatement[4]?.eps ?? 0) /
                                    (growthTableIncomeStatement[1]?.eps ??
                                        1),
                                1 / (growthTableIncomeStatement[1]?.offset ?? 1)
                            ) - 1
                        )}</td>
                    <td className="growth-rate-table-cell">{roundRatio(
                            Math.pow(
                                (growthTableIncomeStatement[4]?.eps ?? 0) /
                                    (growthTableIncomeStatement[0]?.eps ??
                                        1),
                                1 / (growthTableIncomeStatement[0]?.offset ?? 1)
                            ) - 1
                        )}</td>
                </tr>
                <tr className="growth-rate-table-row">
                    <td className="growth-rate-table-cell">Equity</td>
                    <td className="growth-rate-table-cell">
                        {roundInMillions(
                            growthTableBalanceSheet[0]?.totalEquity ?? 0
                        )}
                    </td>
                    <td className="growth-rate-table-cell">
                        {roundInMillions(
                            growthTableBalanceSheet[1]?.totalEquity ?? 0
                        )}
                    </td>
                    <td className="growth-rate-table-cell">
                        {roundInMillions(
                            growthTableBalanceSheet[2]?.totalEquity ?? 0
                        )}
                    </td>
                    <td className="growth-rate-table-cell">
                        {roundInMillions(
                            growthTableBalanceSheet[3]?.totalEquity ?? 0
                        )}
                    </td>
                    <td className="growth-rate-table-cell">
                        {roundInMillions(
                            growthTableBalanceSheet[4]?.totalEquity ?? 0
                        )}
                    </td>
                    <td className="growth-rate-table-cell">
                        {roundRatio(
                            Math.pow(
                                (growthTableBalanceSheet[4]?.totalEquity ?? 0) /
                                    (growthTableBalanceSheet[3]?.totalEquity ??
                                        1),
                                1 / (growthTableBalanceSheet[3]?.offset ?? 1)
                            ) - 1
                        )}
                    </td>
                    <td className="growth-rate-table-cell">{roundRatio(
                            Math.pow(
                                (growthTableBalanceSheet[4]?.totalEquity ?? 0) /
                                    (growthTableBalanceSheet[2]?.totalEquity ??
                                        1),
                                1 / (growthTableBalanceSheet[2]?.offset ?? 1)
                            ) - 1
                        )}</td>
                    <td className="growth-rate-table-cell">{roundRatio(
                            Math.pow(
                                (growthTableBalanceSheet[4]?.totalEquity ?? 0) /
                                    (growthTableBalanceSheet[1]?.totalEquity ??
                                        1),
                                1 / (growthTableBalanceSheet[1]?.offset ?? 1)
                            ) - 1
                        )}</td>
                    <td className="growth-rate-table-cell">{roundRatio(
                            Math.pow(
                                (growthTableBalanceSheet[4]?.totalEquity ?? 0) /
                                    (growthTableBalanceSheet[0]?.totalEquity ??
                                        1),
                                1 / (growthTableBalanceSheet[0]?.offset ?? 1)
                            ) - 1
                        )}</td>
                </tr>
                <tr className="growth-rate-table-row">
                    <td className="growth-rate-table-cell">Free Cash Flow</td>
                    <td className="growth-rate-table-cell">
                        {roundInMillions(
                            growthTableCashFlows[0]?.freeCashFlow ?? 0
                        )}
                    </td>
                    <td className="growth-rate-table-cell">
                        {roundInMillions(
                            growthTableCashFlows[1]?.freeCashFlow ?? 0
                        )}
                    </td>
                    <td className="growth-rate-table-cell">
                        {roundInMillions(
                            growthTableCashFlows[2]?.freeCashFlow ?? 0
                        )}
                    </td>
                    <td className="growth-rate-table-cell">
                        {roundInMillions(
                            growthTableCashFlows[3]?.freeCashFlow ?? 0
                        )}
                    </td>
                    <td className="growth-rate-table-cell">
                        {roundInMillions(
                            growthTableCashFlows[4]?.freeCashFlow ?? 0
                        )}
                        
                    </td>
                    <td className="growth-rate-table-cell">
                        {roundRatio(
                            Math.pow(
                                (growthTableCashFlows[4]?.freeCashFlow ?? 0) /
                                    (growthTableCashFlows[3]?.freeCashFlow ??
                                        1),
                                1 / (growthTableCashFlows[3]?.offset ?? 1)
                            ) - 1
                        )}
                    </td>
                    <td className="growth-rate-table-cell">{roundRatio(
                            Math.pow(
                                (growthTableCashFlows[4]?.freeCashFlow ?? 0) /
                                    (growthTableCashFlows[2]?.freeCashFlow ??
                                        1),
                                1 / (growthTableCashFlows[2]?.offset ?? 1)
                            ) - 1
                        )}</td>
                    <td className="growth-rate-table-cell">{roundRatio(
                            Math.pow(
                                (growthTableCashFlows[4]?.freeCashFlow ?? 0) /
                                    (growthTableCashFlows[1]?.freeCashFlow ??
                                        1),
                                1 / (growthTableCashFlows[1]?.offset ?? 1)
                            ) - 1
                        )}</td>
                    <td className="growth-rate-table-cell">{roundRatio(
                            Math.pow(
                                (growthTableCashFlows[4]?.freeCashFlow ?? 0) /
                                    (growthTableCashFlows[0]?.freeCashFlow ??
                                        1),
                                1 / (growthTableCashFlows[0]?.offset ?? 1)
                            ) - 1
                        )}</td>
                </tr>
                <tr
                    className="growth-rate-table-row"
                    style={{ height: 36 }}
                ></tr>
                <tr className="growth-rate-table-row">
                    <td className="growth-rate-table-cell">Operating CF</td>
                    <td className="growth-rate-table-cell">
                        {roundInMillions(
                            growthTableCashFlows[0]?.operatingCashFlow ?? 0
                        )}
                    </td>
                    <td className="growth-rate-table-cell">
                        {roundInMillions(
                            growthTableCashFlows[1]?.operatingCashFlow ?? 0
                        )}
                    </td>
                    <td className="growth-rate-table-cell">
                        {roundInMillions(
                            growthTableCashFlows[2]?.operatingCashFlow ?? 0
                        )}
                    </td>
                    <td className="growth-rate-table-cell">
                        {roundInMillions(
                            growthTableCashFlows[3]?.operatingCashFlow ?? 0
                        )}
                    </td>
                    <td className="growth-rate-table-cell">
                        {roundInMillions(
                            growthTableCashFlows[4]?.operatingCashFlow ?? 0
                        )}
                    </td>
                    <td className="growth-rate-table-cell">
                        {roundRatio(
                            Math.pow(
                                (growthTableCashFlows[4]?.operatingCashFlow ?? 0) /
                                    (growthTableCashFlows[3]?.operatingCashFlow ??
                                        1),
                                1 / (growthTableCashFlows[3]?.offset ?? 1)
                            ) - 1
                        )}
                    </td>
                    <td className="growth-rate-table-cell">{roundRatio(
                            Math.pow(
                                (growthTableCashFlows[4]?.operatingCashFlow ?? 0) /
                                    (growthTableCashFlows[2]?.operatingCashFlow ??
                                        1),
                                1 / (growthTableCashFlows[2]?.offset ?? 1)
                            ) - 1
                        )}</td>
                    <td className="growth-rate-table-cell">{roundRatio(
                            Math.pow(
                                (growthTableCashFlows[4]?.operatingCashFlow ?? 0) /
                                    (growthTableCashFlows[1]?.operatingCashFlow ??
                                        1),
                                1 / (growthTableCashFlows[1]?.offset ?? 1)
                            ) - 1
                        )}</td>
                    <td className="growth-rate-table-cell">{roundRatio(
                            Math.pow(
                                (growthTableCashFlows[4]?.operatingCashFlow ?? 0) /
                                    (growthTableCashFlows[0]?.operatingCashFlow ??
                                        1),
                                1 / (growthTableCashFlows[0]?.offset ?? 1)
                            ) - 1
                        )}</td>
                </tr>
                <tr className="growth-rate-table-row">
                    <td className="growth-rate-table-cell">CAPEX</td>
                    <td className="growth-rate-table-cell">
                        {roundInMillions(
                            growthTableCashFlows[0]?.capitalExpenditure ?? 0
                        )}
                    </td>
                    <td className="growth-rate-table-cell">
                        {roundInMillions(
                            growthTableCashFlows[1]?.capitalExpenditure ?? 0
                        )}
                    </td>
                    <td className="growth-rate-table-cell">
                        {roundInMillions(
                            growthTableCashFlows[2]?.capitalExpenditure ?? 0
                        )}
                    </td>
                    <td className="growth-rate-table-cell">
                        {roundInMillions(
                            growthTableCashFlows[3]?.capitalExpenditure ?? 0
                        )}
                    </td>
                    <td className="growth-rate-table-cell">
                        {roundInMillions(
                            growthTableCashFlows[4]?.capitalExpenditure ?? 0
                        )}
                    </td>
                    <td className="growth-rate-table-cell">
                        {roundRatio(
                            Math.pow(
                                (growthTableCashFlows[4]?.capitalExpenditure ?? 0) /
                                    (growthTableCashFlows[3]?.capitalExpenditure ??
                                        1),
                                1 / (growthTableCashFlows[3]?.offset ?? 1)
                            ) - 1
                        )}
                    </td>
                    <td className="growth-rate-table-cell">{roundRatio(
                            Math.pow(
                                (growthTableCashFlows[4]?.capitalExpenditure ?? 0) /
                                    (growthTableCashFlows[2]?.capitalExpenditure ??
                                        1),
                                1 / (growthTableCashFlows[2]?.offset ?? 1)
                            ) - 1
                        )}</td>
                    <td className="growth-rate-table-cell">{roundRatio(
                            Math.pow(
                                (growthTableCashFlows[4]?.capitalExpenditure ?? 0) /
                                    (growthTableCashFlows[1]?.capitalExpenditure ??
                                        1),
                                1 / (growthTableCashFlows[1]?.offset ?? 1)
                            ) - 1
                        )}</td>
                    <td className="growth-rate-table-cell">{roundRatio(
                            Math.pow(
                                (growthTableCashFlows[4]?.capitalExpenditure ?? 0) /
                                    (growthTableCashFlows[0]?.capitalExpenditure ??
                                        1),
                                1 / (growthTableCashFlows[0]?.offset ?? 1)
                            ) - 1
                        )}</td>
                </tr>
                <tr className="growth-rate-table-row">
                    <td className="growth-rate-table-cell">CAGR Check</td>
                    <td className="growth-rate-table-cell">
                        {roundInMillions(
                            growthTableCashFlows[0]?.cagr_check ?? 0
                        )}
                    </td>
                    <td className="growth-rate-table-cell">
                        {roundInMillions(
                            growthTableCashFlows[1]?.cagr_check ?? 0
                        )}
                    </td>
                    <td className="growth-rate-table-cell">
                        {roundInMillions(
                            growthTableCashFlows[2]?.cagr_check ?? 0
                        )}
                    </td>
                    <td className="growth-rate-table-cell">
                        {roundInMillions(
                            growthTableCashFlows[3]?.cagr_check ?? 0
                        )}
                    </td>
                    <td className="growth-rate-table-cell">
                        {roundInMillions(
                            growthTableCashFlows[4]?.cagr_check ?? 0
                        )}
                    </td>
                    <td className="growth-rate-table-cell">
                        {roundRatio(
                            Math.pow(
                                (growthTableCashFlows[4]?.cagr_check ?? 0) /
                                    (growthTableCashFlows[3]?.cagr_check ??
                                        1),
                                1 / (growthTableCashFlows[3]?.offset ?? 1)
                            ) - 1
                        )}
                    </td>
                    <td className="growth-rate-table-cell">{roundRatio(
                            Math.pow(
                                (growthTableCashFlows[4]?.cagr_check ?? 0) /
                                    (growthTableCashFlows[2]?.cagr_check ??
                                        1),
                                1 / (growthTableCashFlows[2]?.offset ?? 1)
                            ) - 1
                        )}</td>
                    <td className="growth-rate-table-cell">{roundRatio(
                            Math.pow(
                                (growthTableCashFlows[4]?.cagr_check ?? 0) /
                                    (growthTableCashFlows[1]?.cagr_check ??
                                        1),
                                1 / (growthTableCashFlows[1]?.offset ?? 1)
                            ) - 1
                        )}</td>
                    <td className="growth-rate-table-cell">{roundRatio(
                            Math.pow(
                                (growthTableCashFlows[4]?.cagr_check ?? 0) /
                                    (growthTableCashFlows[0]?.cagr_check ??
                                        1),
                                1 / (growthTableCashFlows[0]?.offset ?? 1)
                            ) - 1
                        )}</td>
                </tr>
            </tbody>
        </table>
    );
};

export default GrowthRateTable;
