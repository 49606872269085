import React from "react";
import "./DCFNotes.scss";

const DCFNotes = () => {
    return (
        <div className="notes-container">
            <div className="notes-header">Notes</div>
            <textarea />
        </div>
    );
};

export default DCFNotes;
