import {
    CashFlowFromFinancingActivitiesLabels,
    CashFlowFromInvestingActivitiesLabels,
    CashFlowFromOperatingActivitiesLabels,
    CashFlowLabels,
    ChangeInCashAndCashEquivalentsLabels,
    NetIncomeLabels,
} from "../../../constants/FinancialTableLabels";
import { useEffect, useState } from "react";
import ControlFilter from "../../../components/ControlFilters/ControlFilter";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import "./CashFlowsSheetPage.scss";
import { formatCashFlowsData } from "../../../utils/financials/FetchFinancials";
import {
    CashFlow,
    CashFlowFromFinancingActivities,
    CashFlowFromInvestingActivities,
    CashFlowFromOperatingActivities,
    ChangeInCashAndCashEquivalents,
    NetIncome,
} from "../../../types/FinancialDataTypes";
import FullNewTable, {
    IndividualTableProps,
} from "../../../components/NewTable/FullNewTable";

const CashFlowsSheetPage = () => {
    const cashFlowsByReport = useSelector(
        (state: RootState) => state.cashFlowsByReport.cashFlows
    );
    const [data, setData] = useState<any>([]);
    const [netIncomeTable, setNetIncomeTable] =
        useState<IndividualTableProps<NetIncome>>();
    const [
        cashFlowFromOperatingActivitiesTable,
        setCashFlowFromOperatingActivitiesTable,
    ] = useState<IndividualTableProps<CashFlowFromOperatingActivities>>();
    const [
        cashFlowFromInvestingActivitiesTable,
        setCashFlowFromInvestingActivitiesTable,
    ] = useState<IndividualTableProps<CashFlowFromInvestingActivities>>();
    const [
        cashFlowFromFinancingActivitiesTable,
        setCashFlowFromFinancingActivitiesTable,
    ] = useState<IndividualTableProps<CashFlowFromFinancingActivities>>();
    const [
        changeInCashAndCashEquivalentsTable,
        setChangeInCashAndCashEquivalentsTable,
    ] = useState<IndividualTableProps<ChangeInCashAndCashEquivalents>>();
    const [cashFlowTable, setCashFlowTable] =
        useState<IndividualTableProps<CashFlow>>();

    useEffect(() => {
        if (cashFlowsByReport) {
            let newData = formatCashFlowsData(cashFlowsByReport);
            setData(newData);
        }
    }, [cashFlowsByReport]);

    useEffect(() => {
        let netIncome: IndividualTableProps<NetIncome> = {
            title: "Net Income",
            data: data.netIncome,
            labels: NetIncomeLabels,
        };

        let cashFlowFromOperatingActivities: IndividualTableProps<CashFlowFromOperatingActivities> =
            {
                title: "Cash Flow From Operating Activities",
                data: data.cashFlowFromOperatingActivities,
                labels: CashFlowFromOperatingActivitiesLabels,
            };

        let cashFlowFromInvestingActivities: IndividualTableProps<CashFlowFromInvestingActivities> =
            {
                title: "Cash Flow From Investing Activities",
                data: data.cashFlowFromInvestingActivities,
                labels: CashFlowFromInvestingActivitiesLabels,
            };

        let cashFlowFromFinancingActivities: IndividualTableProps<CashFlowFromFinancingActivities> =
            {
                title: "Cash Flow From Financing Activities",
                data: data.cashFlowFromFinancingActivities,
                labels: CashFlowFromFinancingActivitiesLabels,
            };

        let changeInCashAndCashEquivalents: IndividualTableProps<ChangeInCashAndCashEquivalents> =
            {
                title: "Change In Cash And Cash Equivalents",
                data: data.changeInCashAndCashEquivalents,
                labels: ChangeInCashAndCashEquivalentsLabels,
            };

        let cashFlow: IndividualTableProps<CashFlow> = {
            title: "Cash Flow",
            data: data.cashFlow,
            labels: CashFlowLabels,
        };

        setNetIncomeTable(netIncome);
        setCashFlowFromOperatingActivitiesTable(
            cashFlowFromOperatingActivities
        );
        setCashFlowFromInvestingActivitiesTable(
            cashFlowFromInvestingActivities
        );
        setCashFlowFromFinancingActivitiesTable(
            cashFlowFromFinancingActivities
        );
        setChangeInCashAndCashEquivalentsTable(changeInCashAndCashEquivalents);
        setCashFlowTable(cashFlow);
    }, [data]);

    return (
        <div className="tables-container">
            <ControlFilter />
            {data &&
                netIncomeTable &&
                cashFlowFromOperatingActivitiesTable &&
                cashFlowFromInvestingActivitiesTable &&
                cashFlowFromFinancingActivitiesTable &&
                changeInCashAndCashEquivalentsTable &&
                cashFlowTable && (
                    <FullNewTable
                        tables={[
                            netIncomeTable,
                            cashFlowFromOperatingActivitiesTable,
                            cashFlowFromInvestingActivitiesTable,
                            cashFlowFromFinancingActivitiesTable,
                            changeInCashAndCashEquivalentsTable,
                            cashFlowTable,
                        ]}
                    />
                )}
        </div>
    );
};

export default CashFlowsSheetPage;
