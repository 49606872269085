import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { getCashFlowsTTM } from '../../utils/api';

type CashFlowState = {
    growthTableCashFlows: {
        offset: number;
        freeCashFlow: number;
        operatingCashFlow: number;
        capitalExpenditure: number;
        cagr_check: number;
    }[];
    loading: 'idle' | 'loading';
    error: string | null;
};

// Initial state
const initialState: CashFlowState = {
    growthTableCashFlows: [],
    loading: 'idle',
    error: null
};

// Create an async thunk for fetching the cash flows
export const fetchCashFlows = createAsyncThunk(
    'cashFlow/fetchCashFlows',
    async (symbol: string) => {
        let year_offsets = [10, 5, 3, 1, 0];
        let cashFlowPromises = year_offsets.map((element) => {
            return getCashFlowsTTM(symbol, element).then((res) => {
                return {
                    offset: element,
                    freeCashFlow: res.freeCashFlow,
                    operatingCashFlow: res.operatingCashFlow,
                    capitalExpenditure: res.capitalExpenditure,
                    cagr_check: res.freeCashFlow + res.operatingCashFlow,
                };
            });
        });

        const results = await Promise.all(cashFlowPromises);
        return results;
    }
);

// Create the slice
const cashFlowSlice = createSlice({
    name: 'cashFlow',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCashFlows.pending, (state) => {
                state.loading = 'loading';
            })
            .addCase(fetchCashFlows.fulfilled, (state, action: PayloadAction<any[]>) => {
                state.loading = 'idle';
                state.growthTableCashFlows = action.payload;
            })
            .addCase(fetchCashFlows.rejected, (state, action) => {
                state.loading = 'idle';
                state.error = action.error.message ?? 'An error occurred';
            });
    }
});

export default cashFlowSlice.reducer;
