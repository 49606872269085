import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const discountRateSlice = createSlice({
  name: 'discountRate',
  initialState: 0.15,
  reducers: {
    setDiscountRate: (state, action: PayloadAction<number>) => {
      return action.payload;
    },
  },
});

export const { setDiscountRate } = discountRateSlice.actions;

export default discountRateSlice.reducer;
