import {
    CashAndShortTermInvestmentsLabels,
    CommonEquityLabels,
    CurrentAssetsLabels,
    CurrentLiabilitiesLabels,
    LiabilitiesAndEquityLabels,
    LongTermAssetsLabels,
    LongTermLiabilitiesLabels,
    PreferredEquityLabels,
    ReceivablesLabels,
    StockholdersEquityLabels,
    SupplementalItemsLabels,
} from "../../../constants/FinancialTableLabels";
import ControlFilter from "../../../components/ControlFilters/ControlFilter";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import './BalanceSheetPage.scss'
import { useEffect, useState } from "react";
import { formatBalanceSheetData } from "../../../utils/financials/FetchFinancials";
import { CashAndShortTermInvestments, CommonEquity, CurrentAssets, CurrentLiabilities, LiabilitiesAndEquity, LongTermAssets, LongTermLiabilities, PreferredEquity, Receivables, StockholdersEquity, SupplementalItems } from "../../../types/FinancialDataTypes";
import FullNewTable, { IndividualTableProps } from "../../../components/NewTable/FullNewTable";

const BalanceSheetPage = () => {
    const balanceSheetsByReport = useSelector((state: RootState) => state.balanceSheetsByReport.balanceSheets);
    const [data, setData] = useState<any>([]);
    const [cashAndShortTermInvestmentsTable, setCashAndShortTermInvestmentsTable] = useState<IndividualTableProps<CashAndShortTermInvestments>>();
    const [receivablesTable, setReceivablesTable] = useState<IndividualTableProps<Receivables>>();
    const [currentAssetsTable, setCurrentAssetsTable] = useState<IndividualTableProps<CurrentAssets>>();
    const [longTermAssetsTable, setLongTermAssetsTable] = useState<IndividualTableProps<LongTermAssets>>();
    const [currentLiabilitiesTable, setCurrentLiabilitiesTable] = useState<IndividualTableProps<CurrentLiabilities>>();
    const [longTermLiabilitiesTable, setLongTermLiabilitiesTable] = useState<IndividualTableProps<LongTermLiabilities>>();
    const [preferredEquityTable, setPreferredEquityTable] = useState<IndividualTableProps<PreferredEquity>>();
    const [commonEquityTable, setCommonEquityTable] = useState<IndividualTableProps<CommonEquity>>();
    const [stockholdersEquityTable, setStockholdersEquityTable] = useState<IndividualTableProps<StockholdersEquity>>();
    const [liabilitiesAndEquityTable, setLiabilitiesAndEquityTable] = useState<IndividualTableProps<LiabilitiesAndEquity>>();
    const [supplementalItemsTable, setSupplementalItemsTable] = useState<IndividualTableProps<SupplementalItems>>();


    useEffect(() => {
        if (balanceSheetsByReport) {
            let newData = formatBalanceSheetData(balanceSheetsByReport);
            setData(newData);
        } 
    }, [balanceSheetsByReport]);

    useEffect(() => {
        let cashAndShortTermInvestments: IndividualTableProps<CashAndShortTermInvestments> = {
            title: "Cash and Short Term Investments",
            data: data.cashAndShortTermInvestments,
            labels: CashAndShortTermInvestmentsLabels,
        }

        let receivables: IndividualTableProps<Receivables> = {
            title: "Receivables",
            data: data.receivables,
            labels: ReceivablesLabels,
        }

        let currentAssets: IndividualTableProps<CurrentAssets> = {
            title: "Current Assets",
            data: data.currentAssets,
            labels: CurrentAssetsLabels,
        }

        let longTermAssets: IndividualTableProps<LongTermAssets> = {
            title: "Long Term Assets",
            data: data.longTermAssets,
            labels: LongTermAssetsLabels,
        }

        let currentLiabilities: IndividualTableProps<CurrentLiabilities> = {
            title: "Current Liabilities",
            data: data.currentLiabilities,
            labels: CurrentLiabilitiesLabels,
        }

        let longTermLiabilities: IndividualTableProps<LongTermLiabilities> = {
            title: "Long Term Liabilities",
            data: data.longTermLiabilities,
            labels: LongTermLiabilitiesLabels,
        }

        let preferredEquity: IndividualTableProps<PreferredEquity> = {
            title: "Preferred Equity",
            data: data.preferredEquity,
            labels: PreferredEquityLabels,
        }

        let commonEquity: IndividualTableProps<CommonEquity> = {
            title: "Common Equity",
            data: data.commonEquity,
            labels: CommonEquityLabels,
        }

        let stockholdersEquity: IndividualTableProps<StockholdersEquity> = {
            title: "Stockholders Equity",
            data: data.stockholdersEquity,
            labels: StockholdersEquityLabels,
        }

        let liabilitiesAndEquity: IndividualTableProps<LiabilitiesAndEquity> = {
            title: "Liabilities and Equity",
            data: data.liabilitiesAndEquity,
            labels: LiabilitiesAndEquityLabels,
        }

        let supplementalItems: IndividualTableProps<SupplementalItems> = {
            title: "Supplemental Items",
            data: data.supplementalItems,
            labels: SupplementalItemsLabels,
        }

        setCashAndShortTermInvestmentsTable(cashAndShortTermInvestments);
        setReceivablesTable(receivables);
        setCurrentAssetsTable(currentAssets);
        setLongTermAssetsTable(longTermAssets);
        setCurrentLiabilitiesTable(currentLiabilities);
        setLongTermLiabilitiesTable(longTermLiabilities);
        setPreferredEquityTable(preferredEquity);
        setCommonEquityTable(commonEquity);
        setStockholdersEquityTable(stockholdersEquity);
        setLiabilitiesAndEquityTable(liabilitiesAndEquity);
        setSupplementalItemsTable(supplementalItems);
    }, [data]);

    return (
        <div className="tables-container">
            <ControlFilter />
            {
                data && cashAndShortTermInvestmentsTable && receivablesTable && currentAssetsTable && longTermAssetsTable && currentLiabilitiesTable && longTermLiabilitiesTable && preferredEquityTable && commonEquityTable && stockholdersEquityTable && liabilitiesAndEquityTable && supplementalItemsTable && (
                
                    <FullNewTable tables={[
                        cashAndShortTermInvestmentsTable, 
                        receivablesTable, 
                        currentAssetsTable, 
                        longTermAssetsTable, 
                        currentLiabilitiesTable, 
                        longTermLiabilitiesTable, 
                        preferredEquityTable, 
                        commonEquityTable, 
                        stockholdersEquityTable, 
                        liabilitiesAndEquityTable, 
                        supplementalItemsTable
                    ]} />
                )
            }
        </div>
    );
};

export default BalanceSheetPage;
