import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface GrowthRatesState {
    rates: number[];
    rate: number;
}

const initialState: GrowthRatesState = {
    rates: [0.09, 0.09, 0.09, 0.09, 0.09, 0.09, 0.09, 0.09, 0.09, 0.09, 0.09],
    rate: 0.09,
}

const growthRatesSlice = createSlice({
    name: 'growthRates',
    initialState: initialState,
    reducers: {
        updateRate: (state, action: PayloadAction<{index: number; value: number}>) => {
            state.rates[action.payload.index] = action.payload.value;
        },
        updateAllRates: (state, action: PayloadAction<number>) => {
            state.rate = action.payload;
            state.rates = Array(state.rates.length).fill(action.payload);
        },
        setGrowthRate: (state, action: PayloadAction<number>) => {
            state.rate = action.payload;
        },
    },
});

export const { updateRate, updateAllRates, setGrowthRate } = growthRatesSlice.actions;

export default growthRatesSlice.reducer;