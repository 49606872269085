import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getBalanceSheetLatest } from "../../utils/api";

type TrailingBalanceSheetState = {
    growthTableBalanceSheets: {
        offset: number;
        totalEquity: number;
    }[];
    loading: 'idle' | 'loading';
    error: string | null;
};

const initialState: TrailingBalanceSheetState = {
    growthTableBalanceSheets: [],
    loading: 'idle',
    error: null
}

export const fetchBalanceSheets = createAsyncThunk(
    'balanceSheet/fetchbalanceSheets',
    async (symbol: string) => {
        let year_offsets = [10, 5, 3, 1, 0];
        let balanceSheetPromises = year_offsets.map((element) => {
            return getBalanceSheetLatest(symbol, element).then((res) => {
                return {
                    offset: element,
                    totalEquity: res.totalEquity,
                };
            });
        });

        const results = await Promise.all(balanceSheetPromises);
        return results;
    }
);

const trailingBalanceSheetSlice = createSlice({
    name: 'balanceSheet',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchBalanceSheets.pending, (state) => {
            state.loading = 'loading';
        })
        .addCase(fetchBalanceSheets.fulfilled, (state, action) => {
            state.loading = 'idle';
            state.growthTableBalanceSheets = action.payload;
        })
        .addCase(fetchBalanceSheets.rejected, (state, action) => {
            state.loading = 'idle';
            state.error = action.error.message ?? 'An error occurred';
        });
    }
});

export default trailingBalanceSheetSlice.reducer;
