import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BalanceSheet } from "../../models/BalanceSheet";
import { getBalanceSheet } from "../../utils/api";

type balanceSheetsByReportState = {
    balanceSheets: BalanceSheet[] | null;
    loading: 'idle' | 'loading';
    error: string | null;
};

const initialState: balanceSheetsByReportState = {
    balanceSheets: null,
    loading: 'idle',
    error: null
}

export const fetchBalanceSheetsByReport = createAsyncThunk(
    'balanceSheet/fetchBalanceSheetsByReport',
    async (args: {symbol: string, period: string}) => {
        let balanceSheet = await getBalanceSheet(args.symbol, args.period);
        return balanceSheet;
    }
);

const fetchBalanceSheetsByReportSlice = createSlice({
    name: 'fetchBalanceSheetsByReport',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchBalanceSheetsByReport.pending, (state) => {
            state.loading = 'loading';
        })
        .addCase(fetchBalanceSheetsByReport.fulfilled, (state, action) => {
            state.loading = 'idle';
            state.balanceSheets = action.payload;
        })
        .addCase(fetchBalanceSheetsByReport.rejected, (state, action) => {
            state.loading = 'idle';
            state.error = action.error.message ?? 'An error occurred';
        });
    }
});

export default fetchBalanceSheetsByReportSlice.reducer;