import { useEffect, useState } from "react";
import { CompanyInfo } from "../../models/CompanyInfo";
import { PrePostMarket } from "../../models/PrePostMarket";
import { StockQuote } from "../../models/StockQuote";
import { roundAndFormat } from "../../utils/mathUtils";
import { extractDateAndTime } from "../../utils/timeUtils";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import isBetween from "dayjs/plugin/isBetween";
import "./CompanyHeader.scss";
import { getMarketStatus } from "../../utils/api";
import { MarketStatus } from "../../models/MarketStatus";

dayjs.extend(utc);
dayjs.extend(isBetween);

interface CompanyHeaderProps {
    companyInfo: CompanyInfo | undefined;
    stockQuote: StockQuote | undefined;
    prePostMarketPrice: PrePostMarket | undefined;
}

const CompanyHeader = ({
    companyInfo,
    stockQuote,
    prePostMarketPrice,
}: CompanyHeaderProps) => {
	const [isAfterHours, setIsAfterHours] = useState(false);

    const { formattedDate, formattedTime } = extractDateAndTime(
        stockQuote?.timestamp ?? Date.now()
    );

    const { formattedTime: prePostTime } = extractDateAndTime(
        prePostMarketPrice?.timestamp ?? Date.now(),
        true
    );

    const getPriceDifference = (price: number, postMarketPrice: number) => {
        const difference = postMarketPrice - price;
        const percentage = (difference / price) * 100;

        return { difference, percentage };
    };

    const { difference, percentage } = getPriceDifference(
        stockQuote?.price ?? 0,
        prePostMarketPrice?.ask ?? 0
    );

    useEffect(() => {
        const fetchMarketStatus = async () => {
            const data: MarketStatus = await getMarketStatus();
			setIsAfterHours(data.isTheStockMarketOpen === false);
        };

        fetchMarketStatus();
    }, []);

    return (
        <div className="container">
            <div className="infoContainer">
                <div className="companyDetails">
                    <span className="companyName">
                        {companyInfo?.companyName ?? "Unknown"}
                    </span>
                    <span className="companySymbol">
                        ({companyInfo?.symbol ?? "N/A"})
                    </span>
                </div>
                <div className="priceDetails">
                    <span className="currentPrice">
                        ${stockQuote?.price.toFixed(2)}
                    </span>
                    <span
                        className={
                            stockQuote?.change && stockQuote?.change > 0
                                ? "positive-change"
                                : "negative-change"
                        }
                    >
                        {stockQuote?.change && stockQuote?.change >= 0 ? (
                            <FaArrowUp size={14} />
                        ) : (
                            <FaArrowDown size={14} />
                        )}
                        {stockQuote?.change &&
                            Math.abs(stockQuote?.change)?.toFixed(2)}{" "}
                        ({roundAndFormat(stockQuote?.changesPercentage)}%)
                    </span>
                    <div className="timeDetails">
                        at {formattedTime} on {formattedDate}
                    </div>
                </div>
                {isAfterHours && (
                    <div className="postMarket">
                        Post-Market:
                        <span className="postPrice">
                            ${prePostMarketPrice?.ask?.toFixed(2)}
                        </span>
                        <span
                            className={
                                difference >= 0
                                    ? "positive-change"
                                    : "negative-change"
                            }
                        >
                            {difference >= 0 ? (
                                <FaArrowUp size={14} />
                            ) : (
                                <FaArrowDown size={14} />
                            )}
                            {Math.abs(difference)?.toFixed(2)} (
                            {Math.abs(percentage)?.toFixed(2)}%)
                        </span>
                        at {prePostTime}
                    </div>
                )}
            </div>
        </div>
    );
};

export default CompanyHeader;
