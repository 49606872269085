import { Action, ThunkDispatch, configureStore } from "@reduxjs/toolkit";
import periodReducer from "./slices/periodSlice";
import trailingCashFlowsReducer from "./slices/trailingCashFlowsSlice";
import trailingIncomeStatementsReducer from "./slices/trailingIncomeStatementsSlice";
import trailingBalanceSheetsReducer from "./slices/trailingBalanceSheetSlice";
import discountRateReducer from "./slices/discountRateSlice";
import growthRatesReducer from "./slices/growthRatesSlice";
import priceToFcfReducer from "./slices/priceToFcfSlice";
import companyInfoReducer from "./slices/companyInfoSlice";
import balanceSheetsByReportReducer from "./slices/balanceSheetsByReportSlice";
import cashFlowsByReportReducer from "./slices/cashFlowsByReport";
import incomeStatementsByReportReducer from "./slices/incomeStatementsByReport";
import userFreeCashFlowReducer from "./slices/userFreeCashFlowSlice";
import marginOfSafetyReducer from "./slices/marginOfSafetySlice";

export type AppDispatch = ThunkDispatch<RootState, unknown, Action<string>>;

export interface RootState {
    period: {
        value: string;
    };
    trailingCashFlows: {
        growthTableCashFlows: {
            offset: number;
            freeCashFlow: number;
            operatingCashFlow: number;
            capitalExpenditure: number;
            cagr_check: number;
        }[];
        loading: 'idle' | 'loading';
        error: string | null;
    };
    trailingIncomeStatements: {
        growthTableIncomeStatements: {
            offset: number;
            revenue: number;
            eps: number;
        }[];
        loading: 'idle' | 'loading';
        error: string | null;
    };
    trailingBalanceSheets: {
        growthTableBalanceSheets: {
            offset: number;
            totalEquity: number;
        }[];
        loading: 'idle' | 'loading';
        error: string | null;
    };
    discountRate: number;
    growthRates: {
        rates: number[];
        rate: number;
    };
    priceToFcf: number;
    companyInfo: {
        stockData: any;
        companyInfo: any;
        prePostMarketData: any;
        sharesFloat: any;
        loading: 'idle' | 'loading';
        error: string | null;
    };
    balanceSheetsByReport: {
        balanceSheets: any;
        loading: 'idle' | 'loading';
        error: string | null;
    };
    cashFlowsByReport: {
        cashFlows: any;
        loading: 'idle' | 'loading';
        error: string | null;
    };
    incomeStatementsByReport: {
        incomeStatements: any;
        loading: 'idle' | 'loading';
        error: string | null;
    };
    userFreeCashFlow: number;
    marginOfSafety: number;
}

export const store = configureStore<RootState>({
    reducer: {
        period: periodReducer,
        trailingCashFlows: trailingCashFlowsReducer,
        trailingIncomeStatements: trailingIncomeStatementsReducer,
        trailingBalanceSheets: trailingBalanceSheetsReducer,
        discountRate: discountRateReducer,
        growthRates: growthRatesReducer,
        priceToFcf: priceToFcfReducer,
        companyInfo: companyInfoReducer,
        balanceSheetsByReport: balanceSheetsByReportReducer,
        cashFlowsByReport: cashFlowsByReportReducer,
        incomeStatementsByReport: incomeStatementsByReportReducer,
        userFreeCashFlow: userFreeCashFlowReducer,
        marginOfSafety: marginOfSafetyReducer,
    },
});
