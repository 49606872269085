import { useState, useEffect, useRef } from "react";
import { SearchResult } from "../../models/SearchResult";
import "./SearchBar.scss";
import { getSearchResults } from "../../utils/api";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import SearchBarResult from "./SearchBarResult/SearchBarResult";

interface SearchProps {
    apiUrl: string;
}

const SearchBar: React.FC<SearchProps> = ({ apiUrl }) => {
    const [query, setQuery] = useState("");
    const [noResultsFound, setNoResultsFound] = useState(false);
    const limit = 10;
    const closeDropdown = () => setResults([]);
    const [results, setResults] = useState<SearchResult[]>([]);

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                closeDropdown();
                setNoResultsFound(false);
            }
        };

        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [ref]);

    useEffect(() => {
        const fetchResults = async () => {
            if (query.length === 0) {
                setResults([]);
                return;
            }

            const data = await getSearchResults(query, limit);

            if (data.length === 0) {
                setNoResultsFound(true);
                setResults([]);
            } else {
                setResults(data);
                setNoResultsFound(false);
            }

            
        };

        const timeoutId = setTimeout(() => {
            fetchResults();
        }, 500);

        return () => clearTimeout(timeoutId);
    }, [query, apiUrl, ref]);

    return (
        <div className="search" ref={ref}>
            <input
                className="search-box"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
            />

            <button className="search-clear" onClick={() => setQuery("")}>
                {query !== "" ? (
                    <ClearIcon style={{ color: "#333", fontSize: "2.5em" }} />
                ) : (
                    <SearchIcon style={{ color: "#333", fontSize: "2.5em" }} />
                )}
            </button>

            {results.length > 0 && (
                <div className="search-results">
                    {results.map((result) => (
                        <SearchBarResult
                            result={result}
                            key={result.symbol}
                            closeDropdown={closeDropdown}
                        />
                    ))}
                </div>
            )}
            {noResultsFound && (
                <div className="search-results">
                    <div className="search-result">
                        <div className="company-name">No results found.</div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SearchBar;
