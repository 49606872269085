import React, { useMemo } from "react";
import { BarChart, Bar } from "recharts";

interface SimpleBarChartProps {
    data: any;
}

const SimpleBarChart = ({ data }: SimpleBarChartProps) => {
    const mappedData = useMemo(
        () =>
            data
                .map((value: string) => parseFloat(value))
                .map((value: number) => ({ pv: value }))
                .splice(1, 11),
        [data]
    );

    return (
        <BarChart
            width={58}
            height={35}
            data={mappedData}
            barSize={3}
            margin={{ top: 5, right: 10, left: 10, bottom: 5 }}
        >
            <Bar dataKey="pv" fill="#555" isAnimationActive={false}/>
        </BarChart>
    );
};

export default SimpleBarChart;
