import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CashFlows } from "../../models/CashFlows";
import { getCashFlows } from "../../utils/api";

type CashFlowsByReportState = {
    cashFlows: CashFlows[] | null;
    loading: 'idle' | 'loading';
    error: string | null;
};

const initialState: CashFlowsByReportState = {
    cashFlows: null,
    loading: 'idle',
    error: null
}

export const fetchCashFlowsByReport = createAsyncThunk(
    'cashFlowsByReport/fetchCashFlowsByReport',
    async (args: {symbol: string, period: string}) => {
        let cashFlows = await getCashFlows(args.symbol, args.period)
        return cashFlows;
    }
);

const cashFlowsByReportSlice = createSlice({
    name: 'cashFlowsByReport',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchCashFlowsByReport.pending, (state) => {
            state.loading = 'loading';
        })
        .addCase(fetchCashFlowsByReport.fulfilled, (state, action) => {
            state.loading = 'idle';
            state.cashFlows = action.payload;
        })
        .addCase(fetchCashFlowsByReport.rejected, (state, action) => {
            state.loading = 'idle';
            state.error = action.error.message ?? 'An error occurred';
        });
    }
});

export default cashFlowsByReportSlice.reducer;