import { ReactNode } from "react";
import Sidebar from "../Sidebar/Sidebar";
import "./SymbolTemplate.scss";
import { CompanyInfo } from "../../models/CompanyInfo";
import LinkMenu from "../LinkMenu/LinkMenu";
import SearchBar from "../SearchBar/SearchBar";
import CompanyHeader from "../CompanyHeader/CompanyHeader";
import { StockQuote } from "../../models/StockQuote";
import { PrePostMarket } from "../../models/PrePostMarket";

interface SymbolTemplateProps {
    children: ReactNode;
    symbol: string;
    companyInfo: CompanyInfo | undefined;
    stockQuote: StockQuote | undefined;
    prePostMarketPrice: PrePostMarket | undefined;
}

const SymbolTemplate = ({
    children,
    symbol,
    companyInfo,
    stockQuote,
    prePostMarketPrice
}: SymbolTemplateProps) => {
    return (
        <div className="symbol-template" style={{ backgroundColor: "#fbfbfd"}}>
            <div className="sidebar-container">
                <Sidebar />
            </div>
            <div className="content-area">
                <header>
                    <div className="searchbar-container">
                        <SearchBar apiUrl={"http://api.callistio.com/utils/search?query=qqq&limit=10"}/>
                    </div>
                    <div className="header-content">
                        <CompanyHeader companyInfo={companyInfo} stockQuote={stockQuote} prePostMarketPrice={prePostMarketPrice}/>
                    </div>
                </header>
                <hr />
                    <div>
                        <LinkMenu symbol={symbol}/>
                    </div>
                <hr />
                <main className="main-content">{children}</main>
                <footer>
                    <h1>Copyright {new Date().getFullYear()}</h1>
                </footer>
            </div>
        </div>
    );
};

export default SymbolTemplate;
