import { BalanceSheet } from "../models/BalanceSheet";
import { CashFlows } from "../models/CashFlows";
import { IncomeStatement } from "../models/IncomeStatement";
import {
    CashAndShortTermInvestments,
    CashFlow,
    CashFlowFromFinancingActivities,
    CashFlowFromInvestingActivities,
    CashFlowFromOperatingActivities,
    ChangeInCashAndCashEquivalents,
    CommonEquity,
    CurrentAssets,
    CurrentLiabilities,
    EarningsFromContinuingOperations,
    LiabilitiesAndEquity,
    LongTermAssets,
    LongTermLiabilities,
    NetIncome,
    OperatingExpensesAndIncome,
    PreferredEquity,
    Receivables,
    RevenuesTable,
    StockholdersEquity,
    SupplementalItems,
} from "../types/FinancialDataTypes";

// income statements

export function extractRevenuesData(
    apiResponse: IncomeStatement[]
): RevenuesTable[] {
    return apiResponse.map((response) => ({
        calendarYear: cleanDate(response.date),
        revenue: roundInMillions(response.revenue),
        costOfRevenue: roundInMillions(response.costOfRevenue),
        grossProfit: roundInMillions(response.grossProfit),
        grossProfitRatio: roundRatio(response.grossProfitRatio),
    }));
}

export function extractOperatingExpensesData(
    apiResponse: IncomeStatement[]
): OperatingExpensesAndIncome[] {
    return apiResponse.map((response) => ({
        calendarYear: cleanDate(response.date),
        sellingGeneralAndAdministrativeExpenses: roundInMillions(
            response.sellingGeneralAndAdministrativeExpenses
        ),
        researchAndDevelopmentExpenses: roundInMillions(
            response.researchAndDevelopmentExpenses
        ),
        operatingExpenses: roundInMillions(response.operatingExpenses),
        operatingIncome: roundInMillions(response.operatingIncome),
        operatingIncomeRatio: roundRatio(response.operatingIncomeRatio),
    }));
}

export function extractEarningsData(
    apiResponse: IncomeStatement[]
): EarningsFromContinuingOperations[] {
    return apiResponse.map((response) => ({
        calendarYear: cleanDate(response.date),
        interestIncome: roundInMillions(response.interestIncome),
        interestExpense: roundInMillions(response.interestExpense),
        incomeBeforeTax: roundInMillions(response.incomeBeforeTax),
        incomeTaxExpense: roundInMillions(response.incomeTaxExpense),
        netIncome: roundInMillions(response.netIncome),
        netIncomeRatio: roundRatio(response.netIncomeRatio),
    }));
}

// balance sheets

export function extractCashAndShortTermInvestmentsData(
    apiResponse: BalanceSheet[]
): CashAndShortTermInvestments[] {
    return apiResponse.map((response) => ({
        calendarYear: cleanDate(response.date),
        cashAndCashEquivalents: roundInMillions(
            response.cashAndCashEquivalents
        ),
        shortTermInvestments: roundInMillions(response.shortTermInvestments),
        totalShortTermInvestments: roundInMillions(
            response.cashAndCashEquivalents + response.shortTermInvestments
        ),
    }));
}

export function extractReceivablesData(
    apiResponse: BalanceSheet[]
): Receivables[] {
    return apiResponse.map((response) => ({
        calendarYear: cleanDate(response.date),
        netReceivables: roundInMillions(response.netReceivables),
    }));
}

export function extractCurrentAssetsData(
    apiResponse: BalanceSheet[]
): CurrentAssets[] {
    return apiResponse.map((response) => ({
        calendarYear: cleanDate(response.date),
        inventory: roundInMillions(response.inventory),
        otherCurrentAssets: roundInMillions(response.otherCurrentAssets),
        totalCurrentAssets: roundInMillions(
            response.totalCurrentAssets
        ),
    }));
}

export function extractLongTermAssetsData(
    apiResponse: BalanceSheet[]
): LongTermAssets[] {
    return apiResponse.map((response) => ({
        calendarYear: cleanDate(response.date),
        propertyPlantEquipmentNet: roundInMillions(
            response.propertyPlantEquipmentNet
        ),
        goodwill: roundInMillions(response.goodwill),
        intangibleAssets: roundInMillions(response.intangibleAssets),
        totalAssets: roundInMillions(
            response.totalAssets
        ),
    }));
}

export function extractCurrentLiabilitiesData(
    apiResponse: BalanceSheet[]
): CurrentLiabilities[] {
    return apiResponse.map((response) => ({
        calendarYear: cleanDate(response.date),
        accountPayables: roundInMillions(response.accountPayables),
        shortTermDebt: roundInMillions(response.shortTermDebt),
        deferredRevenue: roundInMillions(response.deferredRevenue),
        otherCurrentLiabilities: roundInMillions(
            response.otherCurrentLiabilities
        ),
        totalCurrentLiabilities: roundInMillions(
            response.totalCurrentLiabilities
        ),
    }));
}

export function extractLongTermLiabilitiesData(
    apiResponse: BalanceSheet[]
): LongTermLiabilities[] {
    return apiResponse.map((response) => ({
        calendarYear: cleanDate(response.date),
        longTermDebt: roundInMillions(response.longTermDebt),
        deferredRevenueNonCurrent: roundInMillions(
            response.deferredRevenueNonCurrent
        ),
        deferredTaxLiabilitiesNonCurrent: roundInMillions(
            response.deferredTaxLiabilitiesNonCurrent
        ),
        totalLiabilities: roundInMillions(response.totalLiabilities),
    }));
}

export function extractPreferredEquityData(
    apiResponse: BalanceSheet[]
): PreferredEquity[] {
    return apiResponse.map((response) => ({
        calendarYear: cleanDate(response.date),
        preferredStock: roundInMillions(response.preferredStock),
        totalPreferredEquity: roundInMillions(response.preferredStock),
    }));
}

export function extractCommonEquityData(
    apiResponse: BalanceSheet[]
): CommonEquity[] {
    return apiResponse.map((response) => ({
        calendarYear: cleanDate(response.date),
        commonStock: roundInMillions(response.commonStock),
        retainedEarnings: roundInMillions(response.retainedEarnings),
        accumulatedOtherComprehensiveIncomeLoss: roundInMillions(
            response.accumulatedOtherComprehensiveIncomeLoss
        ),
    }));
}

export function extractStockholdersEquityData(
    apiResponse: BalanceSheet[]
): StockholdersEquity[] {
    return apiResponse.map((response) => ({
        calendarYear: cleanDate(response.date),
        minorityInterest: roundInMillions(response.minorityInterest),
        totalStockholdersEquity: roundInMillions(
            response.totalStockholdersEquity
        ),
        totalEquity: roundInMillions(response.totalEquity),
    }));
}

export function extractLiabilitiesAndEquityData(
    apiResponse: BalanceSheet[]
): LiabilitiesAndEquity[] {
    return apiResponse.map((response) => ({
        calendarYear: cleanDate(response.date),
        totalLiabilitiesAndStockholdersEquity: roundInMillions(
            response.totalLiabilitiesAndStockholdersEquity
        ),
        totalLiabilitiesAndTotalEquity: roundInMillions(
            response.totalLiabilitiesAndTotalEquity
        ),
    }));
}

export function extractSupplementalItemsData(
    apiResponse: BalanceSheet[]
): SupplementalItems[] {
    return apiResponse.map((response) => ({
        calendarYear: cleanDate(response.date),
        totalDebt: roundInMillions(response.totalDebt),
        netDebt: roundInMillions(response.netDebt),
    }));
}

// cash flows

export function extractNetIncomeData(apiResponse: CashFlows[]): NetIncome[] {
    return apiResponse.map((response) => ({
        calendarYear: cleanDate(response.date),
        netIncome: roundInMillions(response.netIncome),
    }));
}

export function extractCashFlowFromOperatingActivitiesData(
    apiResponse: CashFlows[]
): CashFlowFromOperatingActivities[] {
    return apiResponse.map((response) => ({
        calendarYear: cleanDate(response.date),
        depreciationAndAmortization: roundInMillions(
            response.depreciationAndAmortization
        ),
        stockBasedCompensation: roundInMillions(
            response.stockBasedCompensation
        ),
        accountsReceivables: roundInMillions(response.accountsReceivables),
        inventory: roundInMillions(response.inventory),
        accountsPayables: roundInMillions(response.accountsPayables),
        otherWorkingCapital: roundInMillions(response.otherWorkingCapital),
        netCashProvidedByOperatingActivities: roundInMillions(
            response.netCashProvidedByOperatingActivities
        ),
    }));
}

export function extractCashFlowFromInvestingActivitiesData(
    apiResponse: CashFlows[]
): CashFlowFromInvestingActivities[] {
    return apiResponse.map((response) => ({
        calendarYear: cleanDate(response.date),
        investmentsInPropertyPlantAndEquipment: roundInMillions(
            response.investmentsInPropertyPlantAndEquipment
        ),
        acquisitionsNet: roundInMillions(response.acquisitionsNet),
        netCashUsedForInvestingActivites: roundInMillions(
            response.netCashUsedForInvestingActivites
        ),
    }));
}

export function extractCashFlowFromFinancingActivitiesData(
    apiResponse: CashFlows[]
): CashFlowFromFinancingActivities[] {
    return apiResponse.map((response) => ({
        calendarYear: cleanDate(response.date),
        debtRepayment: roundInMillions(response.debtRepayment),
        dividendsPaid: roundInMillions(response.dividendsPaid),
        commonStockRepurchased: roundInMillions(response.commonStockRepurchased),
        dividendsPaidAndStockRepurchased: roundInMillions(response.dividendsPaid + response.commonStockRepurchased),
        dividendsPaidAndStockRepurchasedRatio: roundRatio(-1 * ((response.dividendsPaid + response.commonStockRepurchased) / response.revenue)),
        netCashUsedProvidedByFinancingActivities: roundInMillions(response.netCashUsedProvidedByFinancingActivities)
    }));
}

export function extractChangeInCashAndCashEquivalentsData(
    apiResponse: CashFlows[]
): ChangeInCashAndCashEquivalents[] {
    return apiResponse.map((response) => ({
        calendarYear: cleanDate(response.date),
        netChangeInCash: roundInMillions(response.netChangeInCash),
    }));
}

export function extractCashFlowData(apiResponse: CashFlows[]): CashFlow[] {
    return apiResponse.map((response) => ({
        calendarYear: cleanDate(response.date),
        freeCashFlow: roundInMillions(response.freeCashFlow),
        cashFlowMargin: roundRatio(response.freeCashFlow / response.revenue),
    }));
}

// helpers

export const roundRatio = (ratio: number): string => {
    return `${(Math.round(ratio * 10000) / 100).toFixed(2)}%`;
};

export const roundInMillions = (value: number, truncate: number = 1): string => {
    let roundedValue = Math.round((value / 1000000) * 10) / 10;
    let fixedString = roundedValue.toFixed(truncate);

    // Add commas
    let parts = fixedString.split(".");
    parts[0] = parseFloat(parts[0]).toLocaleString("en-US");

    // Rejoin with decimal
    let finalString = parts.join(".");

    return finalString;
};

const cleanDate = (dateString: string): string => {
    if (dateString === "TTM" || dateString === "Latest Report") return dateString;

    const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];

    const date = new Date(dateString);
    const month = date.getMonth();
    const year = date.getFullYear();

    return `${months[month]} ${year}`;
};