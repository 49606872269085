import { EarningsFromContinuingOperations, RevenuesTable, OperatingExpensesAndIncome, NetIncome, CashAndShortTermInvestments, Receivables, CurrentAssets, LongTermAssets, CurrentLiabilities, LongTermLiabilities, PreferredEquity, CommonEquity, StockholdersEquity, LiabilitiesAndEquity, SupplementalItems, CashFlowFromOperatingActivities, CashFlowFromInvestingActivities, CashFlowFromFinancingActivities, CashFlow, ChangeInCashAndCashEquivalents } from "../types/FinancialDataTypes";

export const RevenuesLabels: Record<keyof RevenuesTable, string> = {
    calendarYear: "Calendar Year",
    revenue: "Revenue",
    costOfRevenue: "Cost of Revenue",
    grossProfit: "Gross Profit",
    grossProfitRatio: "Gross Profit Ratio",
};

export const OperatingExpensesLabels: Record<
    keyof OperatingExpensesAndIncome,
    string
> = {
    calendarYear: "Calendar Year",
    sellingGeneralAndAdministrativeExpenses:
        "Selling, General and Administrative Expenses",
    researchAndDevelopmentExpenses: "R&D Expenses",
    operatingExpenses: "Operating Expenses",
    operatingIncome: "Operating Income",
    operatingIncomeRatio: "Operating Income Ratio",
};

export const EarningsFromContinuingOperationsLabels: Record<
    keyof EarningsFromContinuingOperations,
    string
> = {
    calendarYear: "Calendar Year",
    interestIncome: "Interest Income",
    interestExpense: "Interest Expense",
    incomeBeforeTax: "Income Before Tax",
    incomeTaxExpense: "Income Tax Expense",
    netIncome: "Net Income",
    netIncomeRatio: "Net Income Ratio",
};

export const NetIncomeLabels: Record<keyof NetIncome, string> = {
    calendarYear: "Calendar Year",
    netIncome: "Net Income",
}

export const CashAndShortTermInvestmentsLabels: Record<keyof CashAndShortTermInvestments, string> = {
    calendarYear: "Calendar Year",
    cashAndCashEquivalents: "Cash and Equivalents",
    shortTermInvestments: "Short Term Investments",
    totalShortTermInvestments: "Total Short Term Investments",
}

export const ReceivablesLabels: Record<keyof Receivables, string> = {
    calendarYear: "Calendar Year",
    netReceivables: "Net Receivables",
}

export const CurrentAssetsLabels: Record<keyof CurrentAssets, string> = {
    calendarYear: "Calendar Year",
    inventory: "Inventory",
    otherCurrentAssets: "Other Current Assets",
    totalCurrentAssets: "Total Current Assets",
}

export const LongTermAssetsLabels: Record<keyof LongTermAssets, string> = {
    calendarYear: "Calendar Year",
    propertyPlantEquipmentNet: "Property, Plant & Equipment",
    goodwill: "Goodwill",
    intangibleAssets: "Intangible Assets",
    totalAssets: "Total Assets",
}

export const CurrentLiabilitiesLabels: Record<keyof CurrentLiabilities, string> = {
    calendarYear: "Calendar Year",
    accountPayables: "Accounts Payable",
    shortTermDebt: "Short Term Debt",
    deferredRevenue: "Deferred Revenue",
    otherCurrentLiabilities: "Other Current Liabilities",
    totalCurrentLiabilities: "Total Current Liabilities",
}

export const LongTermLiabilitiesLabels: Record<keyof LongTermLiabilities, string> = {
    calendarYear: "Calendar Year",
    longTermDebt: "Long Term Debt",
    deferredRevenueNonCurrent: "Deferred Revenue Non Current",
    deferredTaxLiabilitiesNonCurrent: "Deferred Tax Liabilities Non Current",
    totalLiabilities: "Total Liabilities",
}

export const PreferredEquityLabels: Record<keyof PreferredEquity, string> = {
    calendarYear: "Calendar Year",
    preferredStock: "Preferred Stock",
}

export const CommonEquityLabels: Record<keyof CommonEquity, string> = {
    calendarYear: "Calendar Year",
    commonStock: "Common Stock",
    retainedEarnings: "Retained Earnings",
    accumulatedOtherComprehensiveIncomeLoss: "Accumulated Other Comprehensive Income Loss",
}

export const StockholdersEquityLabels: Record<keyof StockholdersEquity, string> = {
    calendarYear: "Calendar Year",
    minorityInterest: "Minority Interest",
    totalEquity: "Total Equity",
}

export const LiabilitiesAndEquityLabels: Record<keyof LiabilitiesAndEquity, string> = {
    calendarYear: "Calendar Year",
    totalLiabilitiesAndStockholdersEquity: "Total Liabilities and Stockholders Equity",
    totalLiabilitiesAndTotalEquity: "Total Liabilities and Equity",
}

export const SupplementalItemsLabels: Record<keyof SupplementalItems, string> = {
    calendarYear: "Calendar Year",
    totalDebt: "Total Debt",
    netDebt: "Net Debt"
}

export const CashFlowFromOperatingActivitiesLabels: Record<keyof CashFlowFromOperatingActivities, string> = {
    calendarYear: "Calendar Year",
    depreciationAndAmortization: "Depreciation and Amortization",
    stockBasedCompensation: "Stock Based Compensation",
    accountsReceivables: "Change in Accounts Receivables",
    inventory: "Change in Inventory",
    accountsPayables: "Change in Accounts Payables",
    otherWorkingCapital: "Other Working Capital",
    netCashProvidedByOperatingActivities: "Net Cash Provided By Operating Activities",
}

export const CashFlowFromInvestingActivitiesLabels: Record<keyof CashFlowFromInvestingActivities, string> = {
    calendarYear: "Calendar Year",
    investmentsInPropertyPlantAndEquipment: "Capital Expenditure",
    acquisitionsNet: "Cash Acquisitions",
    netCashUsedForInvestingActivites: "Cash from Investing",
}

export const CashFlowFromFinancingActivitiesLabels: Record<keyof CashFlowFromFinancingActivities, string> = {
    calendarYear: "Calendar Year",
    debtRepayment: "Debt Repaid",
    dividendsPaid: "Dividends Paid",
    commonStockRepurchased: "Common Stock Repurchased",
    dividendsPaidAndStockRepurchased: "Total Returned to Shareholders",
    dividendsPaidAndStockRepurchasedRatio: "Return to Shareholder Ratio",
    netCashUsedProvidedByFinancingActivities: "Cash from Financing",
}

export const ChangeInCashAndCashEquivalentsLabels: Record<keyof ChangeInCashAndCashEquivalents, string> = {
    calendarYear: "Calendar Year",
    netChangeInCash: "Net Change in Cash",
}

export const CashFlowLabels: Record<keyof CashFlow, string> = {
    calendarYear: "Calendar Year",
    freeCashFlow: "Free Cash Flow",
    cashFlowMargin: "Cash Flow Margin",
}