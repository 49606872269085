import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const marginOfSafetySlice = createSlice({
  name: 'marginOfSafety',
  initialState: 0.65,
  reducers: {
    setMarginOfSafety: (state, action: PayloadAction<number>) => {
      return action.payload;
    },
  },
});

export const { setMarginOfSafety } = marginOfSafetySlice.actions;

export default marginOfSafetySlice.reducer;
