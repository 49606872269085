import "./CurrentRatioTable.css";
import { roundInMillions, roundRatio } from "../../utils/DataTransformations";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

interface CurrentRatioTableProps {
    setBalanceSheetValue: (value: number) => void;
}

const CurrentRatioTable = ({ setBalanceSheetValue }: CurrentRatioTableProps) => {
    const balanceSheets = useSelector((state: RootState) => state.balanceSheetsByReport?.balanceSheets);
    
    let assets = 0;
    let currentAssets = 0;
    let liabilities = 0;
    let currentLiabilities = 0;
    let preferredStock = 0;

    if (balanceSheets == null || !balanceSheets[0]) {
        return null;
    }
    else 
    {
        assets = balanceSheets[0]?.totalAssets;
        currentAssets = balanceSheets[0]?.totalCurrentAssets;
        liabilities = balanceSheets[0]?.totalLiabilities;
        currentLiabilities = balanceSheets[0]?.totalCurrentLiabilities;
        preferredStock = balanceSheets[0]?.preferredStock;
    }

    

    return (
        <table className="current-ratio-table">
            <tbody>
                <tr className="current-ratio-table-row">
                    <td className="current-ratio-table-cell">Assets</td>
                    <td className="current-ratio-table-cell">{roundInMillions(assets)}</td>
                </tr>
                <tr className="current-ratio-table-row">
                    <td className="current-ratio-table-cell">Current</td>
                    <td className="current-ratio-table-cell">{roundInMillions(currentAssets)}</td>
                </tr>
                <tr className="current-ratio-table-row">
                    <td className="current-ratio-table-cell">Liabilities</td>
                    <td className="current-ratio-table-cell">{roundInMillions(liabilities)}</td>
                </tr>
                <tr className="current-ratio-table-row">
                    <td className="current-ratio-table-cell">Current</td>
                    <td className="current-ratio-table-cell">{roundInMillions(currentLiabilities)}</td>
                </tr>
                <tr className="current-ratio-table-row">
                    <td className="current-ratio-table-cell">Preferred Stock</td>
                    <td className="current-ratio-table-cell">{roundInMillions(preferredStock)}</td>
                </tr>
                <tr className="current-ratio-table-row">
                    <td className="current-ratio-table-cell">Balance Sheet</td>
                    <td className="current-ratio-table-cell">{roundInMillions(assets - liabilities - preferredStock)}</td>
                </tr>
                <tr className="current-ratio-table-row">
                    <td className="current-ratio-table-cell">Current Ratio</td>
                    <td className="current-ratio-table-cell">{roundRatio(currentAssets / currentLiabilities)}</td>
                </tr>
            </tbody>
        </table>
    );
};

export default CurrentRatioTable;
