import React, { useEffect } from "react";
import "./DiscountedCashFlowsTable.css";
import { roundInMillions } from "../../../utils/DataTransformations";
import { useSelector, useDispatch } from "react-redux";
import { updateRate } from "../../../redux/slices/growthRatesSlice";
import { setUserFreeCashFlow } from "../../../redux/slices/userFreeCashFlowSlice";

interface DataTableProps {
    data: any;
    priceToFcf: number;
    setIntrinsicValue: (value: number) => void;
    balanceSheetValue: number;
}

const DiscountedCashFlowsTable = ({
    data,
    priceToFcf,
    setIntrinsicValue,
    balanceSheetValue,
}: DataTableProps) => {
    const discountRate = useSelector((state: any) => state.discountRate);
    const userFreeCashFlow = useSelector(
        (state: any) => state.userFreeCashFlow
    );
    const dispatch = useDispatch();
    const growthRates = useSelector((state: any) => state.growthRates.rates);

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        index: number
    ) => {
        let value = parseFloat(e.target.value);
        if (isNaN(value)) {
            value = 0;
        }
        dispatch(updateRate({ index, value: value / 100 }));
    };

    const handleUpdateFreeCashFlow = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = parseFloat(e.target.value);
        dispatch(setUserFreeCashFlow(value * 1000000));
    };

    useEffect(() => {
        let temp =
            data.reduce((acc: number, item: any) => {
                acc = acc + item.discountedFcf;
                return acc;
            }, (data[data.length - 1]?.fcf * priceToFcf) / Math.pow(1 + discountRate, 10)) +
            balanceSheetValue;

        setIntrinsicValue(temp);
    }, [
        growthRates,
        discountRate,
        data,
        priceToFcf,
        setIntrinsicValue,
        balanceSheetValue,
    ]);

    return (
        <table className="dcf-table">
            <thead>
                <tr>
                    <th className="dcf-table-header">Growth Rates</th>
                    <th className="dcf-table-header">Year</th>
                    <th className="dcf-table-header">Free Cash Flow</th>
                    <th className="dcf-table-header">Discounted FCF</th>
                </tr>
            </thead>
            <tbody>
                <tr key={data[0]?.year} className="dcf-table-row">
                    <td className="dcf-table-cell">
                        <input
                            type="number"
                            step={1}
                            min={0}
                            max="100"
                            value={(growthRates[0] * 100).toFixed(0)}
                            onChange={(e) => handleInputChange(e, 0)}
                            disabled={true}
                        />
                        %
                    </td>
                    <td className="dcf-table-cell">{data[0]?.year}</td>
                    <td className="dcf-table-cell">
                        <input
                            type="number"
                            step={1}
                            min={0}
                            value={(userFreeCashFlow / 1000000)}
                            className={`${userFreeCashFlow.toFixed(0) !== data[0]?.fcf.toFixed(0) ? "changed-input" : ""}`}
                            onChange={(e) =>
                                handleUpdateFreeCashFlow(e)
                            }
                        />
                    </td>
                    <td className="dcf-table-cell"></td>
                </tr>
                {data.slice(1, data.length).map((row: any, index: number) => (
                    <tr key={row.year} className="dcf-table-row">
                        <td className="dcf-table-cell">
                            <input
                                type="number"
                                step={1}
                                min={0}
                                max="100"
                                value={(growthRates[index] * 100).toFixed(0)}
                                onChange={(e) => handleInputChange(e, index)}
                            />
                            %
                        </td>
                        <td className="dcf-table-cell">{row.year}</td>
                        <td className="dcf-table-cell">
                            ${roundInMillions(row?.fcf, 2)}
                        </td>
                        <td className="dcf-table-cell">
                            {`$${roundInMillions(row.discountedFcf, 2)}`}
                        </td>
                    </tr>
                ))}
                <tr className="dcf-table-row">
                    <td className="dcf-table-cell"></td>

                    <td className="dcf-table-cell">Terminal Value</td>
                    <td className="dcf-table-cell">
                        {`$${roundInMillions(
                            data[data.length - 1]?.fcf * priceToFcf,
                            2
                        )}`}
                    </td>
                    <td className="dcf-table-cell">
                        {`$${roundInMillions(
                            (data[data.length - 1]?.fcf * priceToFcf) /
                                Math.pow(1 + discountRate, 10),
                            2
                        )}`}
                    </td>
                </tr>
                <tr className="dcf-table-row">
                    <td className="dcf-table-cell"></td>

                    <td className="dcf-table-cell">Intrinsic Value</td>
                    <td className="dcf-table-cell"></td>
                    <td className="dcf-table-cell">
                        {`$${roundInMillions(
                            data.reduce((acc: number, item: any) => {
                                acc = acc + item.discountedFcf;
                                return acc;
                            }, (data[data.length - 1]?.fcf * priceToFcf) / Math.pow(1 + discountRate, 10)) +
                                balanceSheetValue,
                            2
                        )}`}
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default DiscountedCashFlowsTable;
