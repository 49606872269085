import React from "react";
import "./IntrinsicShareTable.css";
import { roundRatio } from "../../utils/DataTransformations";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { setMarginOfSafety } from "../../redux/slices/marginOfSafetySlice";

interface IntrinsicShareTableProps {
    intrinsicValue: number;
    outstandingShares: number;
}

const IntrinsicShareTable = ({ intrinsicValue, outstandingShares }: IntrinsicShareTableProps) => {
    const marginOfSafety = useSelector((state: RootState) => state.marginOfSafety);
    const stockPrice = useSelector((state: RootState) => state.companyInfo.stockData?.price ?? 0);

    const dispatch = useDispatch();

    let intrinsicShare = intrinsicValue / outstandingShares;

    const handleMarginOfSafetyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setMarginOfSafety(e.target.valueAsNumber / 100));
    }
        
    
    return (
        <table className="intrinsic-share-table">
            <tbody>
                <tr className="intrinsic-share-table-row">
                    <td className="intrinsic-share-table-cell">Intrinsic Share</td>
                    <td className="intrinsic-share-table-cell"></td>
                    <td className="intrinsic-share-table-cell">${intrinsicShare.toFixed(2)}</td>
                </tr>
                <tr className="intrinsic-share-table-row">
                    <td className="intrinsic-share-table-cell">Market Price</td>
                    <td className="intrinsic-share-table-cell">${stockPrice.toFixed(2)}</td>
                    <td className="intrinsic-share-table-cell">{roundRatio(stockPrice / intrinsicShare)}</td>
                </tr>
                <tr className="intrinsic-share-table-row">
                    <td className="intrinsic-share-table-cell">{`${marginOfSafety ? ((marginOfSafety * 100) + "% ") : ""}MOS Target`}</td>
                    <td className="intrinsic-share-table-cell">{`${marginOfSafety ? "$" + ((intrinsicShare * marginOfSafety).toFixed(2)) : ""}`}</td>
                    <td className="intrinsic-share-table-cell">
                        <input type="number" min={0} value={marginOfSafety * 100} onChange={handleMarginOfSafetyChange} />
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default IntrinsicShareTable;
