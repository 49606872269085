import React from "react";
import "./FullNewTable.scss";
import SimpleBarChart from "../SimpleBarChart";

export interface IndividualTableProps<T extends Record<string, any>> {
    title: string;
    data: T[];
    labels: Record<keyof T, string>;
}

export interface FullNewTableProps<T extends Record<string, any>> {
    tables: IndividualTableProps<T>[];
}

const FullNewTable = ({ tables }: FullNewTableProps<any>) => {
    let boldedLabels = [
        "totalShortTermInvestments",
        "totalAssets",
        "totalCurrentAssets",
        "totalLiabilities",
        "totalCurrentLiabilities",
        "totalEquity",
        "totalLiabilitiesAndTotalEquity",
        "grossProfit",
        "operatingIncome",
        "netIncome",
        "depreciationAndAmortization",
        "netCashUsedProvidedByFinancingActivities",
    ];

    const tableBodyRefs = React.useRef<any>([]); // Track all table body refs

    const handleScroll = (index: number) => {
        const scrollLeft = tableBodyRefs.current[index].scrollLeft;
        tableBodyRefs.current.forEach((ref: any, idx: number) => {
            if (idx !== index) {
                ref.scrollLeft = scrollLeft; // sync other table scrolls with current table
            }
        });
    };

    return (
        <div className="full-table">
            {tables?.map((table: IndividualTableProps<any>, idx: number) => {
                const data = table.data ?? [];
                const labels = table.labels ?? {};

                return (
                    <div className="outer-container" key={table.title}>
                        <div className="table-container">
                            <div className="sticky-column">
                                <div className="sticky-column-header">
                                    {table.title}
                                </div>

                                {Object.entries(labels).slice(1).map(([key, label]) => (
                                    <div
                                        className="sticky-column-label"
                                        key={key}
                                    >
                                        {label}
                                    </div>
                                ))}
                            </div>

                            <div className="chart-column">
                                <div className="column-header">Trend</div>

                                {Object.entries(labels).slice(1).map((item) => (
                                    <div
                                        key={item[0]}
                                        className="chart-column-item"
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <SimpleBarChart
                                            data={data.map(
                                                (row) => row[item[0]]
                                            )}
                                        />
                                    </div>
                                ))}
                            </div>

                            <div
                                className="table-body"
                                onScroll={() => handleScroll(idx)}
                                ref={(el) => (tableBodyRefs.current[idx] = el)}
                            >
                                {data.map((row, rowIndex) => (
                                    <div
                                        className="table-column"
                                        key={row.calendarYear}
                                    >
                                        <div className="column-header">
                                            {row.calendarYear}
                                        </div>

                                        {Object.entries(labels).slice(1)?.map((col) => (
                                            <div
                                                key={col[0]}
                                                className={`${
                                                    boldedLabels.includes(
                                                        col[0]
                                                    )
                                                        ? "bolded "
                                                        : ""
                                                }column-item`}
                                            >
                                                {row[col[0]]}
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default FullNewTable;
