import { useSelector, useDispatch } from "react-redux";
import "./ControlFilter.scss";
import { AppDispatch, RootState } from "../../redux/store";
import { setPeriod } from "../../redux/slices/periodSlice";
import { fetchBalanceSheetsByReport } from "../../redux/slices/balanceSheetsByReportSlice";


const ControlFilter = () => {
    const symbol = useSelector((state: RootState) => state.companyInfo.companyInfo?.symbol);
    const period = useSelector((state: RootState) => state.period.value);
    const dispatch = useDispatch<AppDispatch>();
    
    const handleChange = (e: any) => {
        dispatch(setPeriod(e.target.value));
        dispatch(fetchBalanceSheetsByReport({symbol: symbol, period: e.target.value}));
    };

    return (
        <div className="form-group">
            <label htmlFor="period">Period</label>

            <select
                id="period"
                className="form-control"
                value={period}
                onChange={handleChange}
            >
                <option value="annual">Annual</option>
                <option value="quarterly">Quarterly</option>
            </select>
        </div>
    );
};

export default ControlFilter;
