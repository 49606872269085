import { Link, useLocation } from "react-router-dom";
import "./LinkMenu.scss";

interface LinkMenuProps {
    symbol: string;
}

const LinkMenu = ({ symbol }: LinkMenuProps) => {
    const { pathname } = useLocation();

    return (
        <div className="link-menu">
            <Link
                to={`/symbol/${symbol}/income-statement`}
                className={pathname.includes('/income-statement') ? 'active' : ''}
            >
                Income Statement
            </Link>
            <Link
                to={`/symbol/${symbol}/balance-sheet`}
                className={pathname.includes('/balance-sheet') ? 'active' : ''}
            >
                Balance Sheet
            </Link>
            <Link
                to={`/symbol/${symbol}/cash-flows`}
                className={pathname.includes('/cash-flows') ? 'active' : ''}
            >
                Cash Flows
            </Link>
            <Link
                to={`/symbol/${symbol}/discounted-cash-flows`}
                className={pathname.includes('/discounted-cash-flows') ? 'active' : ''}
            >
                Discounted Cash Flows
            </Link>
        </div>
    );
};

export default LinkMenu;
