import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StockQuote } from "../../models/StockQuote";
import {
    getCompanyInfo,
    getPrePostMarket,
    getSharesFloat,
    getStockQuote,
} from "../../utils/api";
import { CompanyInfo } from "../../models/CompanyInfo";
import { PrePostMarket } from "../../models/PrePostMarket";
import { AppDispatch } from "../store";
import { SharesFloat } from "../../models/SharesFloat";

type CompanyInfoState = {
    stockData: StockQuote | null;
    companyInfo: CompanyInfo | null;
    prePostMarketData: PrePostMarket | null;
    sharesFloat: SharesFloat | null;
    loading: "idle" | "loading";
    error: string | null;
};

const initialState: CompanyInfoState = {
    stockData: null,
    companyInfo: null,
    prePostMarketData: null,
    sharesFloat: null,
    loading: "idle",
    error: null,
};

export const fetchStockQuote = createAsyncThunk(
    "companyInfo/fetchStockQuote",
    async (symbol: string) => {
        const response = await getStockQuote(symbol);
        return response;
    }
);

export const fetchCompanyInfo = createAsyncThunk(
    "companyInfo/fetchCompanyInfo",
    async (symbol: string) => {
        const response = await getCompanyInfo(symbol);
        return response;
    }
);

export const fetchPrePostMarketData = createAsyncThunk(
    "companyInfo/fetchPrePostMarketData",
    async (symbol: string) => {
        const response = await getPrePostMarket(symbol);
        return response;
    }
);

export const fetchSharesFloat = createAsyncThunk(
    "companyInfo/fetchSharesFloat",
    async (symbol: string) => {
        const response = await getSharesFloat(symbol);
        return response;
    }
);

export function loadAllCompanyData(symbol: string) {
    return async (dispatch: AppDispatch) => {
        await dispatch(fetchStockQuote(symbol));
        await dispatch(fetchCompanyInfo(symbol));
        await dispatch(fetchPrePostMarketData(symbol));
        await dispatch(fetchSharesFloat(symbol));
    };
}

const companyInfoSlice = createSlice({
    name: "companyInfo",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchStockQuote.pending, (state) => {
                state.loading = "loading";
            })
            .addCase(fetchStockQuote.fulfilled, (state, action) => {
                state.loading = "idle";
                state.stockData = action.payload[0];
            })
            .addCase(fetchStockQuote.rejected, (state, action) => {
                state.loading = "idle";
                state.error = action.error.message ?? "An error occurred";
            });
        builder
            .addCase(fetchCompanyInfo.pending, (state) => {
                state.loading = "loading";
            })
            .addCase(fetchCompanyInfo.fulfilled, (state, action) => {
                state.loading = "idle";
                state.companyInfo = action.payload[0];
            })
            .addCase(fetchCompanyInfo.rejected, (state, action) => {
                state.loading = "idle";
                state.error = action.error.message ?? "An error occurred";
            });
        builder
            .addCase(fetchPrePostMarketData.pending, (state) => {
                state.loading = "loading";
            })
            .addCase(fetchPrePostMarketData.fulfilled, (state, action) => {
                state.loading = "idle";
                state.prePostMarketData = action.payload;
            })
            .addCase(fetchPrePostMarketData.rejected, (state, action) => {
                state.loading = "idle";
                state.error = action.error.message ?? "An error occurred";
            });
        builder
            .addCase(fetchSharesFloat.pending, (state) => {
                state.loading = "loading";
            })
            .addCase(fetchSharesFloat.fulfilled, (state, action) => {
                state.loading = "idle";
                state.sharesFloat = action.payload[0];
            })
            .addCase(fetchSharesFloat.rejected, (state, action) => {
                state.loading = "idle";
                state.error = action.error.message ?? "An error occurred";
            });
    },
});

export default companyInfoSlice.reducer;
