import { BalanceSheet } from "../../models/BalanceSheet";
import { CashFlows } from "../../models/CashFlows";
import { IncomeStatement } from "../../models/IncomeStatement";
import { extractCashAndShortTermInvestmentsData, extractCashFlowData, extractCashFlowFromFinancingActivitiesData, extractCashFlowFromInvestingActivitiesData, extractCashFlowFromOperatingActivitiesData, extractChangeInCashAndCashEquivalentsData, extractCommonEquityData, extractCurrentAssetsData, extractCurrentLiabilitiesData, extractEarningsData, extractLiabilitiesAndEquityData, extractLongTermAssetsData, extractLongTermLiabilitiesData, extractNetIncomeData, extractOperatingExpensesData, extractPreferredEquityData, extractReceivablesData, extractRevenuesData, extractStockholdersEquityData, extractSupplementalItemsData } from "../DataTransformations";
import { getBalanceSheet, getCashFlows, getIncomeStatement } from "../api";

export const fetchCashFlows = async (symbol: string, period: string = 'annual') => {
    let data = await getCashFlows(symbol, period);

    data = data.slice(0,20);

    // Extract data
    const netIncomeData = extractNetIncomeData(data);
    const cashFlowFromOperatingActivitiesData = extractCashFlowFromOperatingActivitiesData(data);
    const cashFlowFromInvestingActivitiesData = extractCashFlowFromInvestingActivitiesData(data);
    const cashFlowFromFinancingActivitiesData = extractCashFlowFromFinancingActivitiesData(data);
    const changeInCashAndCashEquivalentsData = extractChangeInCashAndCashEquivalentsData(data);
    const cashFlowData = extractCashFlowData(data);
    

    return {
        netIncome: netIncomeData,
        cashFlowFromOperatingActivities: cashFlowFromOperatingActivitiesData,
        cashFlowFromInvestingActivities: cashFlowFromInvestingActivitiesData,
        cashFlowFromFinancingActivities: cashFlowFromFinancingActivitiesData,
        changeInCashAndCashEquivalents: changeInCashAndCashEquivalentsData,
        cashFlow: cashFlowData,
    };
};

export const formatCashFlowsData = (data: CashFlows[]) => {
    
    const netIncomeData = extractNetIncomeData(data);
    const cashFlowFromOperatingActivitiesData = extractCashFlowFromOperatingActivitiesData(data);
    const cashFlowFromInvestingActivitiesData = extractCashFlowFromInvestingActivitiesData(data);
    const cashFlowFromFinancingActivitiesData = extractCashFlowFromFinancingActivitiesData(data);
    const changeInCashAndCashEquivalentsData = extractChangeInCashAndCashEquivalentsData(data);
    const cashFlowData = extractCashFlowData(data);
    

    return {
        netIncome: netIncomeData,
        cashFlowFromOperatingActivities: cashFlowFromOperatingActivitiesData,
        cashFlowFromInvestingActivities: cashFlowFromInvestingActivitiesData,
        cashFlowFromFinancingActivities: cashFlowFromFinancingActivitiesData,
        changeInCashAndCashEquivalents: changeInCashAndCashEquivalentsData,
        cashFlow: cashFlowData,
    };
};

export const fetchBalanceSheet = async (symbol: string, period: string = 'annual') => {
    let data = await getBalanceSheet(symbol, period);

    data = data.slice(0,20);

    // Extract data
    const cashAndShortTermInvestmentsData = extractCashAndShortTermInvestmentsData(data);
    const receivablesData = extractReceivablesData(data);
    const currentAssetsData = extractCurrentAssetsData(data);
    const longTermAssetsData = extractLongTermAssetsData(data);
    const currentLiabilitiesData = extractCurrentLiabilitiesData(data);
    const longTermLiabilitiesData = extractLongTermLiabilitiesData(data);
    const preferredEquityData = extractPreferredEquityData(data);
    const commonEquityData = extractCommonEquityData(data);
    const stockholdersEquityData = extractStockholdersEquityData(data);
    const liabilitiesAndEquityData = extractLiabilitiesAndEquityData(data);
    const supplementalItemsData = extractSupplementalItemsData(data);

    return {
        cashAndShortTermInvestments: cashAndShortTermInvestmentsData,
        receivables: receivablesData,
        currentAssets: currentAssetsData,
        longTermAssets: longTermAssetsData,
        currentLiabilities: currentLiabilitiesData,
        longTermLiabilities: longTermLiabilitiesData,
        preferredEquity: preferredEquityData,
        commonEquity: commonEquityData,
        stockholdersEquity: stockholdersEquityData,
        liabilitiesAndEquity: liabilitiesAndEquityData,
        supplementalItems: supplementalItemsData,
    };
};

export const formatBalanceSheetData = (data: BalanceSheet[]) => {
    const cashAndShortTermInvestmentsData = extractCashAndShortTermInvestmentsData(data);
    const receivablesData = extractReceivablesData(data);
    const currentAssetsData = extractCurrentAssetsData(data);
    const longTermAssetsData = extractLongTermAssetsData(data);
    const currentLiabilitiesData = extractCurrentLiabilitiesData(data);
    const longTermLiabilitiesData = extractLongTermLiabilitiesData(data);
    const preferredEquityData = extractPreferredEquityData(data);
    const commonEquityData = extractCommonEquityData(data);
    const stockholdersEquityData = extractStockholdersEquityData(data);
    const liabilitiesAndEquityData = extractLiabilitiesAndEquityData(data);
    const supplementalItemsData = extractSupplementalItemsData(data);

    return {
        cashAndShortTermInvestments: cashAndShortTermInvestmentsData,
        receivables: receivablesData,
        currentAssets: currentAssetsData,
        longTermAssets: longTermAssetsData,
        currentLiabilities: currentLiabilitiesData,
        longTermLiabilities: longTermLiabilitiesData,
        preferredEquity: preferredEquityData,
        commonEquity: commonEquityData,
        stockholdersEquity: stockholdersEquityData,
        liabilitiesAndEquity: liabilitiesAndEquityData,
        supplementalItems: supplementalItemsData,
    };
};


export const fetchIncomeStatement = async (symbol: string, period: string = 'annual') => {
    let data = await getIncomeStatement(symbol, period);

    data = data.slice(0,20);

    // Extract data
    const revenuesData = extractRevenuesData(data);
    const operatingExpensesData = extractOperatingExpensesData(data);
    const earningsData = extractEarningsData(data);

    return {
        revenues: revenuesData,
        expenses: operatingExpensesData,
        earnings: earningsData,
    };
};

export const formatIncomeStatementData = (data: IncomeStatement[]) => {
    const revenuesData = extractRevenuesData(data);
    const operatingExpensesData = extractOperatingExpensesData(data);
    const earningsData = extractEarningsData(data);

    return {
        revenues: revenuesData,
        expenses: operatingExpensesData,
        earnings: earningsData,
    };
};