import { utcToZonedTime } from 'date-fns-tz';

export const extractDateAndTime = (timestamp: number, inMs = false) => {
    timestamp = inMs ? timestamp : timestamp * 1000;

    const date = new Date(timestamp); 

    if (date.getSeconds() >= 30) {
        date.setMinutes(date.getMinutes() + 1);
    }
    date.setSeconds(0);
    date.setMilliseconds(0);

    const formattedDate = date.toLocaleDateString('en-US');

    const formattedTime = date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' });

    return { formattedDate, formattedTime };
}

export const isTimeBetween4PMand930AMEST = (): boolean => {
    const estTimeZone = 'America/New_York';

    const nowUTC = new Date();

    const nowEST = utcToZonedTime(nowUTC, estTimeZone);

    const hours = nowEST.getHours();
    const minutes = nowEST.getMinutes();

    if (hours > 16 || (hours === 9 && minutes < 30) || hours < 9) {
        return true;
    }

    return false;
}