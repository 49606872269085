import {
    EarningsFromContinuingOperationsLabels,
    OperatingExpensesLabels,
    RevenuesLabels,
} from "../../../constants/FinancialTableLabels";
import { formatIncomeStatementData } from "../../../utils/financials/FetchFinancials";
import { useEffect, useState } from "react";
import ControlFilter from "../../../components/ControlFilters/ControlFilter";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import "./IncomeStatementPage.scss";
import FullNewTable, { IndividualTableProps } from "../../../components/NewTable/FullNewTable";
import { EarningsFromContinuingOperations, RevenuesTable } from "../../../types/FinancialDataTypes";
import { OperatingExpensesAndIncome } from "../../../models/finance/OperatingExpensesAndIncome";

const IncomeStatementPage = () => {
    const incomeStatementsByReport = useSelector((state: RootState) => state.incomeStatementsByReport.incomeStatements);
    const [data, setData] = useState<any>([]);
    const [revenueTable, setRevenueTable] = useState<IndividualTableProps<RevenuesTable>>();
    const [expensesTable, setExpensesTable] = useState<IndividualTableProps<OperatingExpensesAndIncome>>();
    const [earningsTable, setEarningsTable] = useState<IndividualTableProps<EarningsFromContinuingOperations>>();

    useEffect(() => {
        if (incomeStatementsByReport) {
            let newData = formatIncomeStatementData(incomeStatementsByReport);
            setData(newData);
        } 
    }, [incomeStatementsByReport]);

    useEffect(() => {
        let revenue: IndividualTableProps<RevenuesTable> = {
            title: "Revenues",
            data: data.revenues,
            labels: RevenuesLabels,
        }

        let expenses: IndividualTableProps<OperatingExpensesAndIncome> = {
            title: "Operating Expenses and Income",
            data: data.expenses,
            labels: OperatingExpensesLabels,
        }

        let earnings: IndividualTableProps<EarningsFromContinuingOperations> = {
            title: "Earnings from Continuing Operations",
            data: data.earnings,
            labels: EarningsFromContinuingOperationsLabels,
        }

        setRevenueTable(revenue);
        setExpensesTable(expenses);
        setEarningsTable(earnings);
    }, [data]);


    return (
        <div className="tables-container">
            <ControlFilter />
            {
                data && revenueTable && expensesTable && earningsTable && (
                    <FullNewTable tables={[revenueTable, expensesTable, earningsTable]} />
                )
            }
        </div>
    );
};

export default IncomeStatementPage;
