import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Symbol from "./routes/symbol/Symbol/Symbol";
import BalanceSheetPage from "./routes/symbol/BalanceSheet/BalanceSheetPage";
import CashFlowsSheetPage from "./routes/symbol/CashFlow/CashFlowsSheetPage";
import IncomeStatementPage from "./routes/symbol/IncomeStatement/IncomeStatementPage";
import Root from "./routes/Root/Root";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import ErrorBoundary from "./routes/Error/ErrorBoundary";
import DiscountedCashFlowsPage from "./routes/symbol/DiscountedCashFlows/DiscountedCashFlowsPage";
import { Auth0Provider } from "@auth0/auth0-react";
import ProtectedRoute from "./routes/Auth/ProtectedRoute";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
    },
    {
        path: "/symbol/:symbol",
        element: (
            <ProtectedRoute>
                <Symbol />
            </ProtectedRoute>
        ),
        children: [
            {
                path: "balance-sheet",
                element: <BalanceSheetPage />,
            },
            {
                path: "cash-flows",
                element: <CashFlowsSheetPage />,
            },
            {
                path: "income-statement",
                element: <IncomeStatementPage />,
            },
            {
                path: "discounted-cash-flows",
                element: <DiscountedCashFlowsPage />,
            },
        ],
    },
    {
        path: "*",
        element: <ErrorBoundary />,
    },
]);

root.render(
    <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ""}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID ?? ""}
        useRefreshTokens={true}
        cacheLocation="localstorage"
    >
        <Provider store={store}>
            <RouterProvider router={router} />
        </Provider>
    </Auth0Provider>
);
