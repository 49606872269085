import axios from "axios";
import { CashFlows } from "../models/CashFlows";
import { IncomeStatement } from "../models/IncomeStatement";
import { CompanyInfo } from "../models/CompanyInfo";
import { BalanceSheet } from "../models/BalanceSheet";
import { SearchResult } from "../models/SearchResult";
import { StockQuote } from "../models/StockQuote";
import { PrePostMarket } from "../models/PrePostMarket";
import { MarketStatus } from "../models/MarketStatus";
import { SharesFloat } from "../models/SharesFloat";

// todo: update with real API route
const API_URL: string = "http://api.callistio.com";

export const getCashFlows = async (
    symbol: string,
    period: string,
    startYear?: string,
    endYear?: string
): Promise<CashFlows[]> => {
    try {
        const url = `${API_URL}/financials/cash-flows/${symbol}?period=${period}`;

        let params: { startDate?: string; endDate?: string } = {};

        if (startYear) {
            params.startDate = startYear;
        }
        if (endYear) {
            params.endDate = endYear;
        }

        const data = await axios
            .get<CashFlows[]>(url, { params: params })
            .then((response) => response.data);
        return data;
    } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
            console.error("API request error: ", error.message);
        } else {
            console.error("Unexpected error: ", error);
        }
        return Promise.reject(error);
    }
};

export const getCashFlowsTTM = async (
    symbol: string,
    year_offset?: number
): Promise<CashFlows> => {
    try {
        const url = `${API_URL}/financials/cash-flows-ttm/${symbol}`;

        let params: { year_offset?: number } = {};

        if (year_offset) {
            params.year_offset = year_offset;
        }

        const data = await axios
            .get<CashFlows>(url, { params: params })
            .then((response) => response.data);
        return data;
    } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
            console.error("API request error: ", error.message);
        } else {
            console.error("Unexpected error: ", error);
        }
        return Promise.reject(error);
    }
};

export const getIncomeStatement = async (
    symbol: string,
    period: string,
    startYear?: string,
    endYear?: string
): Promise<IncomeStatement[]> => {
    try {
        const url = `${API_URL}/financials/income-statement/${symbol}?period=${period}`;

        let params: { startDate?: string; endDate?: string } = {};

        if (startYear) {
            params.startDate = startYear;
        }
        if (endYear) {
            params.endDate = endYear;
        }

        const data = await axios
            .get<IncomeStatement[]>(url, { params: params })
            .then((response) => response.data);
        return data;
    } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
            console.error("API request error: ", error.message);
        } else {
            console.error("Unexpected error: ", error);
        }
        return Promise.reject(error);
    }
};

export const getIncomeStatementTTM = async (
    symbol: string,
    year_offset?: number
): Promise<IncomeStatement> => {
    try {
        const url = `${API_URL}/financials/income-statement-ttm/${symbol}`;

        let params: { year_offset?: number } = {};

        if (year_offset) {
            params.year_offset = year_offset;
        }

        const data = await axios
            .get<IncomeStatement>(url, { params: params })
            .then((response) => response.data);

        return data;
    } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
            console.error("API request error: ", error.message);
        } else {
            console.error("Unexpected error: ", error);
        }
        return Promise.reject(error);
    }
};

export const getBalanceSheet = async (
    symbol: string,
    period: string,
    startYear?: string,
    endYear?: string
): Promise<BalanceSheet[]> => {
    try {
        const url = `${API_URL}/financials/balance-sheet/${symbol}?period=${period}`;

        let params: { startDate?: string; endDate?: string } = {};

        if (startYear) {
            params.startDate = startYear;
        }
        if (endYear) {
            params.endDate = endYear;
        }

        const data = await axios
            .get<BalanceSheet[]>(url, { params: params })
            .then((response) => response.data);
        return data;
    } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
            console.error("API request error: ", error.message);
        } else {
            console.error("Unexpected error: ", error);
        }
        return Promise.reject(error);
    }
};

export const getBalanceSheetLatest = async (
    symbol: string,
    year_offset?: number
): Promise<BalanceSheet> => {
    try {
        const url = `${API_URL}/financials/balance-sheet-latest/${symbol}`;

        let params: { year_offset?: number } = {};

        if (year_offset) {
            params.year_offset = year_offset;
        }

        const data = await axios
            .get<BalanceSheet>(url, { params: params })
            .then((response) => response.data);
        return data;
    } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
            console.error("API request error: ", error.message);
        } else {
            console.error("Unexpected error: ", error);
        }
        return Promise.reject(error);
    }
};

export const getCompanyInfo = async (
    symbol: string
): Promise<CompanyInfo[]> => {
    try {
        const url = `${API_URL}/info/${symbol}`;

        const data = await axios
            .get<CompanyInfo[]>(url)
            .then((response) => response.data);
        return data;
    } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
            console.error("API request error: ", error.message);
        } else {
            console.error("Unexpected error: ", error);
        }
        return Promise.reject(error);
    }
};

export const getSearchResults = async (
    query: string,
    limit: number = 10
): Promise<SearchResult[]> => {
    try {
        const url = `${API_URL}/utils/search?query=${query}&limit=${limit}`;

        const data = await axios
            .get<SearchResult[]>(url)
            .then((response) => response.data);
        return data;
    } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
            console.error("API request error: ", error.message);
        } else {
            console.error("Unexpected error: ", error);
        }
        return Promise.reject(error);
    }
};

export const getStockQuote = async (symbol: string): Promise<StockQuote[]> => {
    try {
        const url = `${API_URL}/info/quote/${symbol}`;

        const data = await axios
            .get<StockQuote[]>(url)
            .then((response) => response.data);
        return data;
    } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
            console.error("API request error: ", error.message);
        } else {
            console.error("Unexpected error: ", error);
        }
        return Promise.reject(error);
    }
};

export const getSharesFloat = async (symbol: string): Promise<SharesFloat[]> => {
    try {
        const url = `${API_URL}/info/${symbol}/shares_float`;

        const data = await axios
            .get<SharesFloat[]>(url)
            .then((response) => response.data);
        return data;
    } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
            console.error("API request error: ", error.message);
        } else {
            console.error("Unexpected error: ", error);
        }
        return Promise.reject(error);
    }
};

export const getPrePostMarket = async (
    symbol: string
): Promise<PrePostMarket> => {
    try {
        const url = `${API_URL}/info/prepost/${symbol}`;
        
        const data = await axios
            .get<PrePostMarket>(url)
            .then((response) => response.data);
        return data;
    } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
            console.error("API request error: ", error.message);
        } else {
            console.error("Unexpected error: ", error);
        }
        return Promise.reject(error);
    }
};

export const getMarketStatus = async (): Promise<MarketStatus> => {
    try {
        const url = `${API_URL}/info/market_status/is_open`;
        
        const data = await axios
            .get<MarketStatus>(url)
            .then((response) => response.data);
        return data;
    } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
            console.error("API request error: ", error.message);
        } else {
            console.error("Unexpected error: ", error);
        }
        return Promise.reject(error);
    }
}
