import React, { useEffect, useState } from "react";
import "./DCFUserInput.css";
import { useSelector, useDispatch } from "react-redux";
import { setDiscountRate } from "../../redux/slices/discountRateSlice";
import { updateAllRates } from "../../redux/slices/growthRatesSlice";
import { setPriceToFcf } from "../../redux/slices/priceToFcfSlice";
import { RootState } from "../../redux/store";

interface UserInputProps {
    ticker: string;
}

const DCFUserInput = ({ ticker }: UserInputProps) => {
    const dispatch = useDispatch();

    const discountRate = useSelector((state: RootState) => state.discountRate);
    const growthRate = useSelector(
        (state: RootState) => state.growthRates.rate
    );
    const userFreeCashFlow = useSelector((state: RootState) => state.userFreeCashFlow);
    const priceToFcf = useSelector((state: RootState) => state.priceToFcf);
    const cashFlows = useSelector(
        (state: RootState) => state.cashFlowsByReport?.cashFlows
    );

    const companyInfo = useSelector((state: RootState) => state.companyInfo);

    const [formData, setFormData] = useState({
        growthRate: growthRate,
        priceToFcf: priceToFcf,
        discountRate: discountRate,
    });

    useEffect(() => {
        dispatch(
            setPriceToFcf(
                Math.round(
                    companyInfo?.stockData?.marketCap /
                        cashFlows?.[0]?.freeCashFlow
                ) ?? 10
            )
        );
    }, [dispatch, cashFlows, companyInfo])

    useEffect(() => {
        if (cashFlows) {
            setFormData({
                growthRate: growthRate,
                priceToFcf: priceToFcf,
                discountRate: discountRate,
            });
        }
    }, [dispatch, cashFlows, growthRate, discountRate, companyInfo, userFreeCashFlow, priceToFcf]);

    type TableTitlesType = {
        growthRate: string;
        priceToFcf: string;
        discountRate: string;
        [key: string]: string;
    };

    const tableTitles: TableTitlesType = {
        growthRate: "Growth Rate",
        priceToFcf: "Price/FCF",
        discountRate: "Discount Rate",
    };

    const handleInputChange = (key: string, newValue: number) => {
        // Adjust the value if it's a rate (since you're multiplying by 100 for display purposes)
        const adjustedValue = ["growthRate", "discountRate"].includes(key)
            ? newValue / 100
            : newValue;

        const updatedFormData = { ...formData, [key]: adjustedValue };

        setFormData(updatedFormData);

        if (key === "discountRate") {
            dispatch(setDiscountRate(adjustedValue));
        } else if (key === "growthRate") {
            dispatch(updateAllRates(adjustedValue));
        } else if (key === "priceToFcf") {
            dispatch(setPriceToFcf(adjustedValue));
        }
    };

    return (
        <table className="dcf-input-table">
            <thead>
                <tr>
                    <th className="dcf-input-table-header">Ticker</th>
                    <th className="dcf-input-table-header">{ticker}</th>
                </tr>
            </thead>
            <tbody>
                {Object.entries(formData).map(([key, value]) => (
                    <tr className="dcf-input-table-row" key={key}>
                        <td className="dcf-input-table-cell">
                            {tableTitles[key]}
                        </td>
                        <td className="dcf-input-table-cell">
                            <input
                                type="number"
                                step="1"
                                min={0}
                                max="100"
                                value={Math.round(
                                    ["growthRate", "discountRate"].includes(key)
                                        ? value * 100
                                        : value
                                )}
                                onChange={(e) =>
                                    handleInputChange(
                                        key,
                                        Math.round(parseFloat(e.target.value))
                                    )
                                }
                            />
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default DCFUserInput;
