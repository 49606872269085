import React from "react";
import { Link } from "react-router-dom";
import './ErrorBoundary.css';

const ErrorBoundary = () => {
    return (
        <div className="not-found-page">
            <h1>404 - Page Not Found!</h1>
            <p>We're sorry, but the page you were looking for doesn't exist.</p>
            <Link to="/symbol/MSFT/income-statement">Return Home</Link>
        </div>
    );
};

export default ErrorBoundary;
