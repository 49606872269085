import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IncomeStatement } from "../../models/IncomeStatement";
import { getIncomeStatement } from "../../utils/api";

type IncomeStatementsByReportState = {
    incomeStatements: IncomeStatement[] | null;
    loading: 'idle' | 'loading';
    error: string | null;
};

const initialState: IncomeStatementsByReportState = {
    incomeStatements: null,
    loading: 'idle',
    error: null
}

export const fetchIncomeStatementsByReport = createAsyncThunk(
    'incomeStatementsByReport/fetchIncomeStatementsByReport',
    async (args: {symbol: string, period: string}) => {
        let incomeStatements = await getIncomeStatement(args.symbol, args.period)
        return incomeStatements;
    }
);

const incomeStatementsByReportSlice = createSlice({
    name: 'incomeStatementsByReport',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchIncomeStatementsByReport.pending, (state) => {
            state.loading = 'loading';
        })
        .addCase(fetchIncomeStatementsByReport.fulfilled, (state, action) => {
            state.loading = 'idle';
            state.incomeStatements = action.payload;
        })
        .addCase(fetchIncomeStatementsByReport.rejected, (state, action) => {
            state.loading = 'idle';
            state.error = action.error.message ?? 'An error occurred';
        });
    }   
});

export default incomeStatementsByReportSlice.reducer;