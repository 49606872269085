import React, { useEffect, useMemo } from "react";
import { Outlet, useParams } from "react-router-dom";
import SymbolTemplate from "../../../components/templates/SymbolTemplate";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { loadAllCompanyData } from "../../../redux/slices/companyInfoSlice";
import { fetchIncomeStatements } from "../../../redux/slices/trailingIncomeStatementsSlice";
import { fetchBalanceSheets } from "../../../redux/slices/trailingBalanceSheetSlice";
import { fetchCashFlows } from "../../../redux/slices/trailingCashFlowsSlice";
import { fetchBalanceSheetsByReport } from "../../../redux/slices/balanceSheetsByReportSlice";
import { fetchCashFlowsByReport } from "../../../redux/slices/cashFlowsByReport";
import { fetchIncomeStatementsByReport } from "../../../redux/slices/incomeStatementsByReport";
import { getCashFlowsTTM } from "../../../utils/api";
import { setUserFreeCashFlow } from "../../../redux/slices/userFreeCashFlowSlice";

const Symbol = () => {
    const { symbol } = useParams<string>();
    const companyInfo = useSelector((state: RootState) => state.companyInfo);
    const period = useSelector((state: RootState) => state.period.value);
    

    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(loadAllCompanyData(symbol ?? ""));

        dispatch(fetchBalanceSheetsByReport({symbol: symbol ?? "", period: period ?? "annual"}));
        dispatch(fetchCashFlowsByReport({symbol: symbol ?? "", period: period ?? "annual"}));
        dispatch(fetchIncomeStatementsByReport({symbol: symbol ?? "", period: period ?? "annual"}));

        getCashFlowsTTM(symbol ?? "", 0).then((res) => {
            dispatch(setUserFreeCashFlow(res?.freeCashFlow ?? 0));
        });

        dispatch(fetchCashFlows(symbol ?? ""));
        dispatch(fetchIncomeStatements(symbol ?? ""));
        dispatch(fetchBalanceSheets(symbol ?? ""));

    }, [symbol, dispatch, period]);

    const template = useMemo(() => {
        return (
            <SymbolTemplate
                symbol={symbol ?? ""}
                companyInfo={companyInfo.companyInfo}
                stockQuote={companyInfo.stockData}
                prePostMarketPrice={companyInfo.prePostMarketData}
            >
                <Outlet context={{ symbol: symbol }} />
            </SymbolTemplate>
        );
    }, [companyInfo, symbol]);

    return template;
};

export default Symbol;
