import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const priceToFcfSlice = createSlice({
    name: "priceToFcf",
    initialState: 10,
    reducers: {
        setPriceToFcf: (state, action: PayloadAction<number>) => {
            return action.payload;
        },
    },
});

export const { setPriceToFcf } = priceToFcfSlice.actions;

export default priceToFcfSlice.reducer;
