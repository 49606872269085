import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import DiscountedCashFlowsTable from "../../../components/DiscountedCashFlowsTable/DiscountedCashFlowsTable/DiscountedCashFlowsTable";
import DCFUserInput from "../../../components/DCFUserInput/DCFUserInput";
import GrowthRateTable from "../../../components/GrowthRateTable/GrowthRateTable";
import CurrentRatioTable from "../../../components/CurrentRatioTable/CurrentRatioTable";
import {
    getCashFlowsTTM,
    getSharesFloat,
} from "../../../utils/api";
import { CashFlows } from "../../../models/CashFlows";
import IntrinsicShareTable from "../../../components/IntrinsicShareTable/IntrinsicShareTable";
import { SharesFloat } from "../../../models/SharesFloat";
import { updateAllRates } from "../../../redux/slices/growthRatesSlice";
import DCFNotes from "../../../components/DCFNotes/DCFNotes";
import { setUserFreeCashFlow } from "../../../redux/slices/userFreeCashFlowSlice";

const DiscountedCashFlowsPage = () => {
    const dispatch = useDispatch<AppDispatch>();

    const userFreeCashFlow = useSelector((data: RootState) => data.userFreeCashFlow);

    const startingYear = new Date().getFullYear();

    const growthTableCashFlows = useSelector(
        (state: RootState) => state.trailingCashFlows.growthTableCashFlows
    );
    const growthTableIncomeStatement = useSelector(
        (state: RootState) => state.trailingIncomeStatements.growthTableIncomeStatements
    );
    const growthTableBalanceSheet = useSelector(    
        (state: RootState) => state.trailingBalanceSheets.growthTableBalanceSheets
    );

    const discountRate = useSelector((state: RootState) => state.discountRate);
    const growthRates = useSelector(
        (state: RootState) => state.growthRates.rates
    );
    const growthRate = useSelector((state: RootState) => state.growthRates.rate);
    const priceToFcf = useSelector((state: RootState) => state.priceToFcf);

    const [freeCashFlow, setFreeCashFlow] = useState<number>();
    const [formData, setFormData] = useState([]);

    const [cashFlowsTTM, setCashFlowsTTM] = useState<CashFlows>();
    const symbol = useOutletContext<any>().symbol;

    const [sharesFloat, setSharesFloat] = useState<SharesFloat>();
    const [intrinsicValue, setIntrinsicValue] = useState(0);
    const [balanceSheetValue, setBalanceSheetValue] = useState(0);

    useEffect(() => {
        dispatch(updateAllRates(growthRate));
    }, [dispatch, growthRate, symbol]);

    useEffect(() => {
        getCashFlowsTTM(symbol, 0).then((res) => {
            setCashFlowsTTM(res);
        });
        getSharesFloat(symbol).then((res) => {
            setSharesFloat(res[0]);
        });
    }, [dispatch, symbol]);

    useEffect(() => {
        if (cashFlowsTTM) {
            setUserFreeCashFlow(cashFlowsTTM.freeCashFlow);
        }
    }, [cashFlowsTTM]);

    useEffect(() => {
        if (userFreeCashFlow) {
            setFreeCashFlow(userFreeCashFlow);
        }
    }, [userFreeCashFlow])

    useEffect(() => {
        let tempFormData: any = [
            {
                growthRates: growthRates[0],
                year: startingYear,
                fcf: userFreeCashFlow,
                discountedFcf: null,
            },
        ];

        for (let i = 1; i < growthRates.length; i++) {
            tempFormData[i] = {
                growthRates: growthRates[i],
                year: startingYear + i,
                fcf: tempFormData[i - 1].fcf * (1 + growthRates[i]),
                discountedFcf:
                    (tempFormData[i - 1].fcf * (1 + growthRates[i])) /
                    Math.pow(1 + discountRate, i),
            };
        }

        setFormData(tempFormData);
    }, [growthRates, discountRate, userFreeCashFlow, startingYear]);

    return (
        <div className="tables-container" style={{ display: "flex" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                    className="left-panel"
                    style={{
                        flex: 1,
                        padding: "1rem 2rem",
                        gap: "2rem",
                        flexDirection: "column",
                        display: "flex",
                    }}
                >
                    <DCFUserInput
                        ticker={symbol}
                    />
                    <DiscountedCashFlowsTable
                        data={formData}
                        priceToFcf={priceToFcf}
                        setIntrinsicValue={setIntrinsicValue}
                        balanceSheetValue={balanceSheetValue}
                    />
                    <IntrinsicShareTable
                        intrinsicValue={intrinsicValue}
                        outstandingShares={sharesFloat?.outstandingShares ?? 0}
                    />
                </div>
                <div
                    className="right-panel"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                        padding: "1rem 2rem",
                        gap: "2rem",
                        minWidth: 1100,
                    }}
                >
                    <GrowthRateTable
                        growthTableCashFlows={growthTableCashFlows}
                        growthTableIncomeStatement={growthTableIncomeStatement}
                        growthTableBalanceSheet={growthTableBalanceSheet}
                    />

                    <div
                        style={{
                            width: "calc(100% / 4)",
                        }}
                    >
                        <CurrentRatioTable
                            setBalanceSheetValue={setBalanceSheetValue}
                        />
                    </div>
                    <DCFNotes />
                </div>
            </div>
        </div>
    );
};

export default DiscountedCashFlowsPage;
