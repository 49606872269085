import Hero from "../../components/Hero/Hero";
import heroImage from "../../assets/hero-image.png";
import missionMan from "../../assets/mission-man.png";
import SideBySide from "../../components/SideBySide/SideBySide";
import "./Root.scss";
import TickerBarHeader from "../../components/TickerBarHeader/TickerBarHeader";

const Root = () => {
    const tickerList = [
        "MSFT",
        "AAPL",
        "GOOG",
        "AMZN",
        "ABNB",
        "TSLA",
        "NVDA",
        "CAVA",
    ];

    return (
        <div className="standin">
            Working on something...
        </div>
    )

    return (
        <div className="App">
            <div style={{ display: "flex", flexDirection: "column"}}>
            <TickerBarHeader tickerList={tickerList} />
            <Hero
                imageUrl={heroImage}
                mainText="Callistio Finance"
                subText="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            />
                <SideBySide
                    imgSrc={missionMan}
                    content={{
                        title: "Who Are We?",
                        subTitle:
                            "Callistio is your dedicated companion in the realm of investing, empowering individuals with the essential knowledge to navigate the financial markets confidently. ",
                        buttons: [{ text: "Learn More", link: "#" }],
                    }}
                    imgOnLeft={true}
                />
                <hr className="section-divider" />
            </div>
        </div>
    );
};

export default Root;
