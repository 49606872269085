import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { getIncomeStatementTTM } from '../../utils/api';

type IncomeStatementstate = {
    growthTableIncomeStatements: {
        offset: number;
        revenue: number;
        eps: number;
    }[];
    loading: 'idle' | 'loading';
    error: string | null;
};

// Initial state
const initialState: IncomeStatementstate = {
    growthTableIncomeStatements: [],
    loading: 'idle',
    error: null
};

// Create an async thunk for fetching the cash flows
export const fetchIncomeStatements = createAsyncThunk(
    'incomeStatement/fetchIncomeStatements',
    async (symbol: string) => {
        let year_offsets = [10, 5, 3, 1, 0];
        let incomeStatementPromises = year_offsets.map((element) => {
            return getIncomeStatementTTM(symbol, element).then((res) => {
                return {
                    offset: element,
                    revenue: res.revenue,
                    eps: res.eps,
                };
            });
        });

        const results = await Promise.all(incomeStatementPromises);
        return results;
    }
);

// Create the slice
const trailingIncomeStatementsSlice = createSlice({
    name: 'incomeStatement',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchIncomeStatements.pending, (state) => {
                state.loading = 'loading';
            })
            .addCase(fetchIncomeStatements.fulfilled, (state, action: PayloadAction<any[]>) => {
                state.loading = 'idle';
                state.growthTableIncomeStatements = action.payload;
            })
            .addCase(fetchIncomeStatements.rejected, (state, action) => {
                state.loading = 'idle';
                state.error = action.error.message ?? 'An error occurred';
            });
    }
});

export default trailingIncomeStatementsSlice.reducer;
