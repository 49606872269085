import React from "react";
import { SearchResult } from "../../../models/SearchResult";
import { Link } from "react-router-dom";
import './SearchBarResult.scss'

interface ResultProps {
    result: SearchResult;
    closeDropdown: any;
}


const SearchBarResult = ({result, closeDropdown}: ResultProps) => {
    return (
        <Link
            key={result.symbol}
            className="search-result"
            to={`/symbol/${result.symbol}/income-statement`}
            onClick={closeDropdown}
            style={{
                color: "#333",
                textDecoration: "none",
                fontSize: ".75em",
            }}
        >
            <div
                className="company-name"
                style={{
                    color: "#333",
                    textDecoration: "none",
                    fontSize: ".75em",
                }}
            >
                {result.name}
            </div>
            <div
                className="company-symbol"
                style={{
                    color: "#333",
                    textDecoration: "none",
                    fontSize: "1em",
                }}
            >
                {result.symbol}
            </div>
        </Link>
    );
};

export default SearchBarResult;
